.modal-container {
  &__with-close {
    height: 100%;
    padding-top: 3.3rem;
    min-height: 500px;
  }
  &__close-button {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
  }

}
