@import "../../../../assets/styles/abstracts/mixins";
@import "../../../../assets/styles/abstracts/variables";
.upload-image-wrapper {
    // margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    position: relative;
    margin-bottom: 1rem;
    margin-right: 1rem;
    &--sm {
        // width: 180px;
        // height: 100px;
    }
    &--md {
        // width: 212px;
        // height: 241px;
    }
    &--lg {
        // width: 350px;
        // height: 375px;
    }
    &--default {
        // width: 350px;
        // height: 245px;
    }
    &__area {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        font-family: "Arial Regular", "Arial", sans-serif;
        color: #333333;
        text-align: center;
        border: 0.2rem dashed #e7e6e6;
        border-radius: 0.6rem;
        margin: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        padding: 2rem;

        &--drag {
            color: $color_1;
        }
        &__header {
            font-size: 1.6rem;
            font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
            color: #000000;
            text-align: left;
            line-height: normal;
            margin-bottom: 0.3rem;
            text-align: -webkit-center;
        }
        &__extension {
            font-size: 1.2rem;
            font-family: "Nunito-ExtraLight", "Nunito ExtraLight", "Nunito", sans-serif;
            font-weight: 200;
            color: #77878f;
            text-align: left;
            margin-top: 0;
            text-align: -webkit-center;
        }
    }
    &__preview {
        width: 50%;
        background-color: #ffffff;
        font-family: "Arial Regular", "Arial", sans-serif;
        color: #333333;
        text-align: center;
        border: 0.2rem dashed #e7e6e6;
        display: flex;
        height: 100%;
        margin: 0.5rem;

        img {
            padding: 2px;
            height: 200px;
            width: 100%;
            object-fit: contain;
        }
        &__close {
            position: relative;
            top: -0.7rem;
            right: 1.3rem;
            width: 3.5rem;
            height: 2.5rem;
            border-radius: 50%;
            border: 3px solid #37464d;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                width: 1.6rem;
                height: 1.6rem;
            }
        }
    }
}
.error-modal-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    min-height: 45vh;
    @include respond-to(medium) {
        min-height: unset;
        margin-left: 4.5rem;
        margin-right: 4.5rem;
    }
    &__modal-title {
        font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
        color: red;
        text-align: left;
        font-weight: bold;
        font-size: 1.8rem;
    }
    &__modal-description {
        margin-top: 0.5rem;
        font-family: "Nunito", sans-serif;
        color: #37454d;
        text-align: left;
        line-height: normal;
        font-size: 1.8rem;
        margin-bottom: 11rem;
        @include respond-to(medium) {
            margin-bottom: 3rem;
        }
    }
    &__actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 1.9rem;
        margin-left: 10%;
        margin-right: 10%;
        button {
            height: 4rem;
            @include respond-to(medium) {
                height: unset;
            }
        }
        @include respond-to(medium) {
            justify-content: flex-end;
            margin-left: 4.5rem;
            // margin-right: 3.5rem;
            margin-top: 4rem;
            height: unset;
        }
    }
}
.crop-image-modal-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 1.8rem;
    &__modal-title {
        align-self: flex-start;
        font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
        color: #37454d;
        text-align: left;
        font-weight: bold;
        font-size: 1.8rem;
    }
    &__modal-description {
        align-self: flex-start;
        margin-top: 0.5rem;
        font-family: "Nunito", sans-serif;
        color: #37454d;
        text-align: left;
        line-height: normal;
        font-size: 1.8rem;
        margin-bottom: 1.1rem;
    }
    &__no-selection {
        color: red;
    }
    &__actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 1.9rem;
        align-self: flex-end;

        @include respond-to(xsmall) {
            margin-top: 1rem;
        }

        button {
            height: 4rem;
            @include respond-to(medium) {
                height: unset;
            }
        }
        div:first-child {
            margin-right: 0.9rem;
        }
        @include respond-to(medium) {
            justify-content: flex-end;
            margin-left: 4.5rem;
            // margin-right: 3.5rem;
            margin-top: 4rem;
            height: unset;
        }
    }
}
