.config_section {
    padding: 2rem;
    &-container {
        background-color: white;
        box-shadow: 0 2px 4px 0 rgb(187 186 186 / 50%);
        border-radius: 4px;
        padding: 1.5rem 1.4rem;
        margin: 1rem 0rem 4rem 0;
        display: flex;
        position: relative;
        width: 100%;
        font-size: 1.2rem;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        flex-direction: row;
        align-content: center;
        &--group {
            display: flex;
            align-items: center;
            flex-direction: row;
            flex: 1;
            padding: 1rem;

            &_label {
                margin-right: 1rem;
                width: 20%;
            }
            &_field {
                width: 80%;
            }
        }
    }
}
