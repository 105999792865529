@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";

.slider {
  overflow: hidden;
  height: 100%;
  &__item {
    position: relative;
    width: 120px !important;
    padding-top: 2.5rem;
    padding-right: 2.5rem;
    &__image {
      width: 120px !important;
      // @include respond-to(medium) {
      //   max-width: 130%;
      // }
    }
    &__delete-wrapper {
      cursor: pointer;
      z-index: 1;
      position: absolute;
      top: 2.5rem;
      font-size: 4rem;
      right: 0;
      transform: translate(0, 20%);
    }
  }
}
