@import 'react-phone-input-2/lib/material.css';
.react-tel-input .special-label{
    position: absolute;
    pointer-events: none;
    left: 13px;
    top: 12px;
    color: #525252;
    font-weight: 100;
    -moz-transition: 0.2s ease all;
    transition: 0.2s ease all;
    background: transparent;
    padding-left: 5.2rem;
    display: none;
}
.react-tel-input .form-control{
    width: 100%;
    border: 1px solid #dedcdc;
    background-color: #ffffff;
    text-align: left;
    // height: 5.2rem !important;
    font-size: 1.9rem;
    font-family: "Nunito-SemiBold", "Nunito SemiBold", "Nunito", sans-serif;
    color: #000000;
    outline: none;
    height: auto;
    padding: 1.2rem 2.4rem 1.2rem 5.4rem;
    &:focus, &:not(:placeholder-shown)  {
        border-color: rgba(0, 0, 0, 0.1);
        border-width: 2px;
        height: 6rem;
      }
      &:focus ~ .special-label,
      &:not(:placeholder-shown) + special-label {
      top: 8px;
      bottom: 10px;
      left: 13px;
      opacity: 1;
      font-size: 11px !important;
    }
}