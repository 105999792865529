@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";
.visuals-container {
  flex: 1;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 8px;
  background-color: white;
  padding: 1.5rem;
  margin-top: 1rem;
  flex-direction: column;
  @include respond-to(medium) {
    margin-left: 1rem;
  }

  &__sub-title {
    font-weight: bold;
    color: $color_2;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  &__section-title {
    margin-bottom: 2rem;
  }

  &__field {
    margin: 1rem 0;
  }

  &__field-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    &__icon {
      color: $color_2;
      font-size: 2rem;
      margin-right: 2rem;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.9rem;
    button {
      height: 4rem;
      @include respond-to(medium) {
        height: unset;
      }
    }
    div:first-child {
      margin-right: 0.9rem;
    }
    @include respond-to(medium) {
      justify-content: flex-end;
      margin-left: 4.5rem;
      margin-top: 4rem;
      height: unset;
    }
  }
}
