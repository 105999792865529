@import "../../../../assets/styles/abstracts/variables";
@import "../../../../assets/styles/abstracts/mixins";
.filter-selector {
    border-radius: 6px;
    font-size: 1.2rem;
    background-color: rgba(255, 255, 255, 0);
    font-family: "Arial Regular", "Arial", sans-serif;
    color: #333333;
    &__item {
        cursor: pointer;
        padding: 6px 14px 6px 13px;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        border: 1px gray solid;
        // border-radius: 6px;
        &:first-child {
            min-width: 7rem;
            border-radius: 6px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
        }
        // &:nth-child(2) {
        //     min-width: 8.5rem;
        //     border-top-left-radius: 0;
        //     border-bottom-left-radius: 0;
        //     border-left: 0;
        // }
        &:last-child {
            min-width: 8.5rem;
            border-radius: 6px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0;
        }
        &__count {
            font-size: 1rem;
            color: gray;
            margin-left: 0.2rem;
            @include respond-to(medium) {
                margin-bottom: 0.2rem;
            }
        }
        &--separator {
            border-right: 1px solid #b6c4cc;
        }
        &--selected {
            pointer-events: none;
            background-color: #ffd59f;
            // border-radius: 6px;
            border: 1px solid $color_1 !important;
            color: #333333;
        }
    }
}
