@import "../../../../../assets/styles/abstracts/breakpoints";
@import "../../../../../assets/styles/abstracts/mixins";
.comments-thread {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__no-records {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #d9001b;
        flex-direction: column;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        width: 100%;
        > img {
            width: 50%;
        }
        @include respond-to(medium) {
            // width: unset;
            height: 100%;
        }
    }
    &__group {
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    &__content {
        display: flex;
        margin: 1.5rem 0;
        background-color: white;
        border-radius: 8px;
        padding: 0.5rem;
    }
    &__image {
        width: 45px;
        height: 45px;
        object-fit: cover;
        margin-right: 0.3rem;
    }
    &__time-ago {
        color: grey;
        font-size: 1.2rem;
    }
    &__avatar {
        display: flex;
        flex-direction: column;
        &__name {
            font-weight: bold;
            font-size: 1.4rem;
            font-family: Arial, Helvetica, sans-serif;
        }
    }
    &__message {
        margin-top: 0.3rem;
    }
}
