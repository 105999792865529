@import "../../assets/styles/abstracts/variables";
@import "../../assets/styles/abstracts/mixins";
.kpis {
    .kpis-filters-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        @include respond-to(medium) {
          align-items: flex-start;
          background-color: #f5f7fc;
          padding: 3rem 2rem 3rem 2rem;
        }
        &__filters{
            width: 100%;
        }
        &__search {
          border-radius: 8px;
          position: relative;
          background-color: white;
          box-shadow: 0 2px 4px 0 rgba(187, 186, 186, 0.5);
          border-radius: 4px;
          padding: 1.5rem 1.4rem;
          margin: 1rem 0;
          // margin-bottom: 5rem;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          // cursor: pointer;
          position: relative;
          width: 100%;
          font-size: 1.2rem;
          align-items: center;
          @include respond-to(medium) {
            flex-direction: row;
            padding: 1.8rem 2.5rem 1.8rem 2.5rem;
          }
          &__button-container {
            height: -webkit-fill-available;
            padding: 1.8rem 0.5rem 0 2.5rem;
            display: flex;
            align-items: flex-start;
            flex: 1;
            flex-direction: row;
            justify-content: flex-start;
            gap: 8px;
          }
          &__form-field {
            width: 100%;
            padding: 1rem;
            @include respond-to(medium) {
              width: 20%;
              padding: 1.8rem 0.5rem 1.8rem 2.5rem;
            }
            &__label {
              font-weight: 700;
              font-size: 1.2rem;
            }
            &__select {
              > div:first-of-type {
                min-height: 3.5rem;
              }
              position: relative;
              &__form-field {
                @include respond-to(medium) {
                  width: 31%;
                  padding: 1.8rem 0.5rem 1.8rem 2.5rem;
                }
                &__label {
                  font-weight: 700;
                  font-size: 1.8rem;
                }
                &__select {
                  > div:first-of-type {
                    height: 5.5rem;
                  }
                }
              }
              &__label {
                font-family: "Abhaya Libre Medium";
                color: #37454d;
                text-align: left;
                font-weight: 500;
                font-size: 1.4rem;
              }
              &__value {
                font-family: Abel;
                font-weight: 500;
                font-size: 1.8rem;
              }
            }
            &__button-container {
              width: 93%;
              margin-top: 4rem;
              &__btn {
                align-items: flex-end;
              }
            }
          }
          &__button {
            align-items: baseline;
          }
          &__date-range{
            display: flex;
            gap: 1rem;
            > div {
              width: 50%;
            }
          }
          &__actions {
            display: flex;
            gap: 1em;
            padding: 1rem;
            @include respond-to(medium) {
              padding: 1.8rem 0.5rem 1.8rem 2.5rem;
            }
          }
        }
    }
    &__charts {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 3rem;
      gap: 2rem;
      flex-direction: column;
      align-items: flex-start;
      @include respond-to(medium) {
        flex-direction: row;
      }
      > div {
        width: 100%;
        @include respond-to(medium) {
          width: 50%;
        }
      }
      &--nested {
        margin-top: 0;
      }
    }
}
