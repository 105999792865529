@import "../../../../assets/styles/abstracts/variables";
.autosize-textarea {
  border-radius: 4px;
  border: 1px solid #dedcdc;
  background-color: #ffffff;
  text-align: left;
  resize: none;
  min-height: 9.5rem;
  font-size: 1.6rem;
  font-family: "Nunito-SemiBold", "Nunito SemiBold", "Nunito", sans-serif;
  color: #000000;
  padding: 2rem 1.3rem;
  outline: none;
  &:focus {
    border: 2px solid $color_1;
  }
}
