@import "../../../../assets/styles/abstracts/variables";
.tab-list-item {
    list-style: none;
    font-size: 1.4rem;
    position: relative;
    background-color: rgba(255, 255, 255, 0);
    font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
    // font-weight: 700;
    color: #83939c;
    text-align: left;
    margin-right: 3.9rem;
    cursor: pointer;
}

.tab-list-active {
    list-style: none;
    font-size: 1.4rem;
    position: relative;
    background-color: rgba(255, 255, 255, 0);
    font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
    // font-weight: 700;
    color: $color_1;
    text-align: left;
    margin-right: 3.9rem;
    // border-bottom: 1px solid $color_1;
    &:after {
        content: "";
        position: absolute;
        width: 15px;
        height: 1px;
        background: #f38831;
        // transform: translateX(200%);
        bottom: -3px;
        left: 0;
    }
}
