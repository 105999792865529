@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.reservation-list-container {
  @include respond-to(medium) {
    padding: 3rem 2rem 3rem 2rem;
    min-height: calc(100vh - 45px);
    align-items: flex-start;
    background-color: #f5f7fc;
  }
  &__modal {
    :global(div.MuiDialog-container.MuiDialog-scrollPaper) {
      :global(.MuiDialog-paperWidthSm) {
        max-width: 80rem;
      }
    }
  }

  &__title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: $color_2;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 1rem 0;
    flex-direction: column;
    @include respond-to(medium) {
      flex-direction: row;
    }

    &__actions {
      justify-content: space-between;
      display: flex;
      width: 100%;
      @include respond-to(medium) {
        width: 25%;
      }
    }
  }

  &__reservation-item {
    border-radius: 8px;
    position: relative;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(187, 186, 186, 0.5);
    border-radius: 4px;
    padding: 1.5rem 1.4rem;
    margin: 1rem 0;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // cursor: pointer;
    position: relative;
    @include respond-to(medium) {
      flex-direction: row;
      padding: 1.8rem 0.5rem 1.8rem 2.5rem;
    }
    &__status {
      // position: absolute;
      padding: 0.5rem;
      border-radius: 4px;
      font-size: 1rem;
      text-align: center;
      white-space: nowrap;
      margin-bottom: 0.5rem;
      width: fit-content;
      text-transform: capitalize;
      &--highlighted {
        color: #ff0000;
      }
      &--expired {
        background: #e5e5e5;
        color: #ff0000;
      }
      &--confirmation_pending {
        background: #ffd9bc;
        color: #f38831;
      }
      &--checked_in {
        background: #c8ffe4;
        color: #008000;
      }
      &--in_house {
        background: #008343;
        color: white;
      }
      &--checked_out {
        background: #686868;
        color: white;
      }
      &--pending_check_in {
        background: #686868;
        color: white;
      }
      &--checkin_started {
        background: #ffe1c9;
        color: #f38831;
      }
      &--canceled {
        background: #ffacac;
        color: #ff0000;
      }
    }
    &__button-container {
      width: 93%;
      margin-top: 4rem;
      &__btn {
        align-items: flex-end;
      }
    }
    &__reservation-card {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 1.5rem;
      @include respond-to(medium) {
        width: 65%;
        flex-direction: row;
        padding: 3rem 0rem 3rem 3rem;
      }
      &__actions {
        align-self: center;

        text-align: right;
        margin-right: 1.5rem;
      }
    }
  }
}
