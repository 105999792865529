@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";
.assign-unit-container {
  flex: 1;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 8px;
  background-color: white;
  padding: 1.5rem;
  margin-top: 1rem;

  &__sub-title {
    font-weight: bold;
    color: $color_2;
    font-size: 1.6rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  &__field {
    margin: 1rem 0;
  }

  &__cards-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0 6rem;
    place-content: space-around;
    flex-wrap: wrap;

    &__loader {
      padding: 10rem 0;
      text-align: center;
    }
    &__message {
      text-align: center;
      padding: 4rem 3rem;
      border: 1px $color_1 solid;
      background-color: lighten($color: $color_1, $amount: 30);
      border-radius: 8px;
    }

    &__card {
      width: 48%;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      border: 1px grey solid;
      border-radius: 8px;
      text-align: center;
      align-self: stretch;
      @include respond-to(medium) {
        width: 33%;
      }
      &__image-container {
        width: 220px;
        height: 140px;
        max-width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 1rem;
        &__image {
          max-width: 100%;
          max-height: 100%;
          border-radius: 8px;
        }
      }
      &__name {
        font-weight: bold;
        margin-bottom: 1rem;
        flex: 1;
      }
    }
  }
}
