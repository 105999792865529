@import "../../../../assets/styles/abstracts/variables";
@import "../../../../assets/styles/abstracts/mixins";
.body-header {
  display: flex;

  &__logo {
    height: 100%;
  }
}

header {
  height: 45px;
  background-color: $background_color_4;
  padding: 0 5px 0 0;
  color: $color_1;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  // @include respond-to(medium) {
  //   margin-left: 50px;
  // }

  @include respond-to(large) {
    margin-left: 240px;
  }

  .logo {
    margin-left: 1rem;
    height: 100%;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color_2;
    height: 100%;
    text-decoration: none;
    &:link {
      color: $color_2;
      text-decoration: none;
    }

    &:visited {
      color: $color_2;
      text-decoration: none;
    }

    &:hover {
      color: $color_2;
      text-decoration: none;
    }

    &:active {
      color: $color_2;
      text-decoration: none;
    }
  }
  .back-link {
    display: flex;
    color: black;
    margin-left: 1.5rem;
    align-items: center;
    font-size: 1.6rem;
  }

  .user-actions {
    margin-left: auto;
    font-size: 1.4rem;

    .user-actions__button {
      // height: 30px;
      // border-radius: 2px;
      // border: 1px solid #353e6f;
      // // background-color: $background_color_2;
      // box-sizing: border-box;
      // // font-family: $font_family_1;
      // color: $color_3;
      // text-align: center;
      // margin-right: 30px;
      font-size: 1.1rem;

      &:hover {
        // background-color: $background_color_3;
        // color: $color_4;
      }
    }
  }

  .user-info {
    margin-left: auto;
    margin-top: auto;
    .user-data {
      min-width: 75px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 12px;
    }

    .user-data__username {
      // font-family: $font_family_1;
      font-size: 12px;
    }

    .user-data__title {
      // font-family: $font_family_1;
      font-size: 10px;
    }

    .user-avatar {
      min-width: 33px;
      .user-avatar__img {
        width: 33px;
        height: 33px;
        border-radius: 50%;
      }

      .user-avatar__img.hidden {
        display: none;
      }
    }
  }

  .products-links {
    margin: 0px 15px;

    button {
      width: 37px;
      height: 37px;
      border-radius: 50px;
      border: 0;

      &:focus {
        outline: 0;
      }
    }

    .products-links__container {
      margin: 10px 0 0;
      border-radius: 2px;
      border: 1px solid #f2f2f2;
      // font-family: $font_family_1;
      text-align: left;
    }

    .products-links__headline {
      // font-family: $font_family_1;
      font-size: 12px;
      font-weight: bold;
      padding: 15px 10px 10px 28px;
    }

    .products-links__link {
      // font-family: $font_family_2;
      font-size: 13px;
      padding: 10px 10px 10px 30px;

      &:hover {
        // background-color: $background_color_5;
      }
    }
  }

  .side-bar__link-content {
    font-size: 0.9em;
    font-size: 13px;
  }
  .header-container {
    display: flex;
    align-items: center;
    height: 100%;
    flex: 1;

    &__menu-toggle {
      margin-left: 2rem;
      height: 100%;
      font-size: 2rem;
    }
    .children-wrapper {
      width: 75%;
    }

    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.no-side {
  header {
    .user-actions {
      display: none;
    }

    .user-info {
      margin-left: auto;
    }

    .page-title {
      display: flex;
      margin-left: 24px;
    }
  }
}

.page-title {
  > span {
    // font-family: $font_family_1;
    color: $color_6;
    font-size: 14px;
  }
}

.user-nav__initials {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  // border: 1px solid scale-color($background_color_4, $lightness: -30%);
  // background-color: $background_color_4;
  display: flex;

  span {
    position: relative;
    flex: 1;
    align-self: center;
    text-align: center;
    font-weight: 500;
    color: $color_5;
    // font-family: $font_family_2;
  }
}

.user-nav__initials.hidden {
  display: none;
}
