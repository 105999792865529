@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.unauthorized-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  @include respond-to(medium) {
    height: unset;
  }
  &__wrapper {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-left: 2.9rem;
    padding-right: 2.9rem;
    padding-top: 6rem;
    height: 100%;
    align-items: center;
    width: 100%;
    @include respond-to(medium) {
      margin: unset;
      height: unset;
      max-width: 500px;
      border-radius: 4px;
      padding: 3rem 3.5rem;
      justify-content: center;
    }

    &__title {
      font-size: 3rem;
      width: 80%;
      margin-top: 1.6rem;
    }
    &__sub_title {
      font-size: 1.8rem;
      margin-bottom: 3.4rem;
      width: 80%;
    }
    &__submit-button {
      margin-top: 3.8rem;
      margin-bottom: 1.5rem;
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      > div {
        &:first-child {
          margin-right: 2rem;
        }
        &:last-child {
          margin-left: 2rem;
        }
      }
    }
  }
  &__illustration {
    height: 25rem;
    @include respond-to(medium) {
      flex: 0.6;
      height: unset;
    }
    &__image {
      height: 25rem;
      @include respond-to(medium) {
        height: unset;
        width: 100%;
      }
    }
  }
}
