@import "../../../../../assets/styles/abstracts/breakpoints";
@import "../../../../../assets/styles/abstracts/mixins";
.payment-details {
    margin-bottom: 3rem;
    &__header {
      font-weight: 100;
      margin-bottom: 2rem;
    }
    &__dates {
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(187, 186, 186, 0.5);
        border-radius: 8px;
        padding: 1.5rem 1.4rem;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        // cursor: pointer;
        @include respond-to(medium) {
          flex-direction: row;
          padding: 1.8rem 1.5rem 1.8rem 2.5rem;
          align-items: flex-start;
          margin-right: 1rem;
        }
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        border-radius: 8px;
        &__label {
          margin-bottom: 1rem;
          font-family: Abhaya Libre Medium;
          font-weight: 500;
          font-size: 1.1rem;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.7);
          :first-child {
            margin-right: 1rem;
          }
          :last-child {
            margin-left: 1rem;
          }
        }
        &__value {
          font-family: Abel;
          font-size: 1.6rem;
        }
      }
      &__prices {
        margin-top: 2rem;
        &__header {
          font-weight: 700;
        }
        &__item {
          display: flex;
          justify-content: space-between;
          &__name {
            font-weight: 200;
            flex: 1;
          }
          &__discount {
            color: red;
            font-weight: 700;
            text-decoration: line-through;
            margin-right: 1rem;
          }
          &__total {
            font-weight: 700;
          }
          &__sum {
            border-top: 1px solid grey;
            font-weight: 700;
          }
        }
      }
    &__guest-wrapper {
      display: flex;
      flex-direction: column;
      @include respond-to(medium) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    &__guest {
      width: 100%;
      margin-top: 1.5rem;
      @include respond-to(medium) {
        width: 50%;
        padding-right: 1rem;
      }
      &__label {
        margin-bottom: 1rem;
        font-family: Abhaya Libre Medium;
        font-weight: 500;
        font-size: 1.1rem;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.7);
      }
      &__value {
        font-family: Abel;
        font-size: 1.6rem;
      }
    }
  }