@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";
.general-info-container {
  flex: 1;
  margin-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 8px;
  background-color: white;
  padding: 1.5rem;

  &__wrapper {
    width: 100%;
  }

  @include respond-to(medium) {
    width: 50%;
    margin-right: 1rem;
  }

  &__loader {
    align-self: center;
    padding: 10rem 0;
    text-align: center;
  }

  &__sub-title {
    font-weight: bold;
    color: $color_2;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  &__field {
    margin: 1rem 0;
  }

  &__beds {
    background-color: $background_color_4;
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    &__room {
      border-width: 2px;
      border-style: dashed;
      border-color: grey;
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: 8px;
      &__message {
        color: red;
        text-align: center;
        width: 100%;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.9rem;
    width: 100%;
    button {
      width: 100%;
      height: 4rem;
      @include respond-to(medium) {
        height: unset;
      }
    }
    // div:first-child {
    //   margin-right: 0.9rem;
    // }
    @include respond-to(medium) {
      justify-content: flex-end;
      // margin-left: 4.5rem;
      margin-top: 4rem;
      height: unset;
    }
  }
}
