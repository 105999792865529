@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";

.landlord-info-container {
    flex: 1;
    margin-top: 1rem;
    padding-bottom: 2rem;
    border-radius: 8px;
    background-color: white;
    padding: 1.5rem;
    margin-left:1rem;

    @include respond-to(medium) {
        width: 100%;
        margin-right: 1rem;
    }
    &-title {
        font-weight: bold;
        color: $color_2;
        font-size: 1.6rem;
        margin-bottom: 1rem;
    }
    &-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        &__edit-text {
            min-width: 150px;
        }
        &-info {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 20px;
            padding-right: 5px;
            align-items: center;

            label {
                padding-right: 1rem;
                margin-bottom: 1rem;
                font-family: Abhaya Libre Medium;
                font-weight: 500;
                font-size: 1.1rem;
                white-space: nowrap;
                text-transform: uppercase;
                color: rgba(0, 0, 0, 0.7);
            }
            p {
                width: 75%;
                word-wrap: break-word;
                font-size: 14px;
                text-overflow: ellipsis;
                width: 100%;
                white-space: nowrap;
                overflow-x: hidden;
            }
            @include respond-to(medium) {
                width: 50%;
            }
        }
        &-image {
            display: block;
            margin: auto;
        }
        &--hint {
            color: $warning-color;
            margin: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &-actions {
        justify-content: flex-end;
        display: flex;
        // width: 100%;
        button {
            margin-left: 1.5rem;
        }
    }
}
