button[editext="cancel-button"] {
    &:hover {
        background: crimson;
        color: #fff;
    }
}

// div[editext="view-container"] {
//     background: transparent;
//     padding: 15px;
//     border-radius: 5px;
//     color: #fff;
// }

button[editext="edit-button"] {
    background-color: white;
    border: 0;
    border-radius: 5px;
}
button[editext="save-button"] {
    background-color: white;
    border: 0;
    border-radius: 5px;
}
button[editext="cancel-button"] {
    background-color: white;
    border: 0;
    border-radius: 5px;
}
// input,
// textarea {
//     /** or input[editext="input"] {} */
//     background: #1d2225;
//     color: #f4c361;
//     font-weight: bold;
//     border-radius: 5px;
// }
