@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.user-search-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  @include respond-to(medium) {
    min-height: calc(100vh - 45px);
    align-items: flex-start;
    background-color: #f5f7fc;
    padding: 3rem 2rem 3rem 2rem;
  }
  &__loader {
    align-self: center;
    padding: 10rem 0;
    text-align: center;
  }
  &__unlock {
    display: flex;
    align-items: center;
    gap:1rem;
  }
  &__title {
    color: $color_2;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 1rem 0;
  }
  &__add-new {
    display: flex;
    align-self: flex-end;
    font-size: 3.5rem;
    color: $color_2;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  &__search {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: 1rem;
    @include respond-to(medium) {
      width: 50%;
      align-self: center;
    }
    input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      height: unset;
    }
    &__btn {
      height: 100%;
      button {
        height: 100%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  &__content {
    margin-top: 2rem;
    padding-bottom: 2rem;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    &__illustration {
      width: 100%;
      place-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      // color: #d9001b;
      flex-direction: column;
      padding-top: 2.5rem;
      width: 50%;
      @include respond-to(medium) {
        width: 100%;
      }
      &__image {
        width: 100%;
        max-width: 500px;
        object-fit: cover;
      }
    }
    &__title {
      color: $color_1;
      font-size: 1.5rem;
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      font-weight: 500;
      &_icon {
        width: 2.5rem !important;
        height: auto !important;
        margin-right: 1rem;
      }
    }
  }
  &__details-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 100%;
    // @include respond-to(medium) {
    //   // flex-direction: row;
    //   flex-flow: wrap;
    // }
    // @include respond-to(large) {
    //   flex-wrap: wrap;
    //   flex-direction: column;
    // }
    &__user-details {
      background-color: white;
      box-shadow: 0 2px 4px 0 rgba(187, 186, 186, 0.5);
      border-radius: 8px;
      padding: 1.5rem 1.4rem;
      margin: 1rem 0;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      display: block;
      flex: 1;
      @include respond-to(medium) {
        // flex-direction: row;
        padding: 1.8rem 1.5rem 1.8rem 2.5rem;
        align-items: flex-start;
      }
      // width: 100%;
      // @include respond-to(medium) {
      //   width: 60%;
      //   margin-right: 1rem;
      // }
      &__add-guest {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      &__tabs-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &__tabs {
          height: 10%;
        }
        &__add {
          cursor: pointer;
          font-size: 3.5rem;
        }
      }
      // &__guest-wrapper {
      //   display: flex;
      //   flex-direction: column;
      //   @include respond-to(medium) {
      //     flex-direction: row;
      //     flex-wrap: wrap;
      //   }
      // }
      &__guest {
        width: 100%;
        margin-top: 1.5rem;
        @include respond-to(medium) {
          width: 50%;
          padding-right: 1rem;
        }
        &--full-row {
          @include respond-to(medium) {
            width: 100%;
          }
        }
        &__label {
          margin-bottom: 1rem;
          font-family: Abhaya Libre Medium;
          font-weight: 500;
          font-size: 1.1rem;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.7);
        }
        &__value {
          font-family: Abel;
          font-size: 1.6rem;
          @include respond-to(medium) {
            max-width: 500px;
          }
        }
        &__upload-container {
          font-family: Abhaya Libre Medium;
          font-weight: 500;
          font-size: 1.1rem;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.7);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        &__dropzone {
          display: none !important;
        }

        &__link-container {
          border: 1px grey solid;
          border-radius: 4px;
          margin-bottom: 1rem;
          display: flex;
          justify-content: center;
          padding: 1rem;
          text-decoration: none;
          &__image {
            width: 60px;
            margin-right: 1rem;
          }
          &__details {
            display: flex;
            flex-direction: column;
            width: calc(100% - 60px);
            &__filename {
              flex: 1;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              color: black;
              font-size: 1.6rem;
              font-family: Abhaya Libre Medium;
              font-weight: 500;
              text-transform: uppercase;
              color: rgba(0, 0, 0, 0.7);
            }
            &__file-id {
              flex: 1;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              color: lightgrey;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
  &__reservation-item {
    border-radius: 8px;
    position: relative;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(187, 186, 186, 0.5);
    border-radius: 4px;
    padding: 1.5rem 1.4rem;
    margin: 1rem 0;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // cursor: pointer;
    position: relative;
    @include respond-to(medium) {
      flex-direction: row;
      padding: 1.8rem 0.5rem 1.8rem 2.5rem;
    }
    &__status {
      // position: absolute;
      padding: 0.5rem;
      border-radius: 4px;
      font-size: 1rem;
      text-align: center;
      white-space: nowrap;
      margin-bottom: 0.5rem;
      width: fit-content;
      text-transform: capitalize;
      &--highlighted {
        color: #ff0000;
      }
      &--expired {
        background: #e5e5e5;
        color: #ff0000;
      }
      &--confirmation_pending {
        background: #ffd9bc;
        color: #f38831;
      }
      &--checked_in {
        background: #c8ffe4;
        color: #008000;
      }
      &--in_house {
        background: #008343;
        color: white;
      }
      &--checked_in {
        background: #c8ffe4;
        color: #008000;
      }
      &--in_house {
        background: #008343;
        color: white;
      }
      &--verified {
        background: #c8ffe4;
        color: #008000;
      }
      &--confirmed {
        background: #c8ffe4;
        color: #008000;
      }
      &--checked_out {
        background: #686868;
        color: white;
      }
      &--pending_check_in {
        background: #686868;
        color: white;
      }
      &--checkin_started,
      &--in_screening {
        background: #ffe1c9;
        color: #f38831;
      }
      &--canceled {
        background: #ffacac;
        color: #ff0000;
      }
    }
    &__form-field {
      @include respond-to(medium) {
        width: 31%;
        padding: 1.8rem 0.5rem 1.8rem 2.5rem;
      }
      &__label {
        font-weight: 700;
        font-size: 1.2rem;
      }
      &__select {
        > div:first-of-type {
          height: 3.5rem;
        }
        position: relative;
        &__status {
          position: absolute;
          padding: 1rem;
          border-radius: 1rem;
          top: -2rem;
          &--expired {
            background: #e5e5e5;
            color: #ff0000;
            display: block;
          }
          &--confirmation_pending {
            background: #f5f7fc;
            color: #f38831;
          }
          &--checked_in {
            background: #c8ffe4;
            color: #008000;
          }
          &--in_house {
            background: #008343;
            color: white;
          }
          &--checked_out {
            background: #686868;
            color: white;
          }
          &--checkin-started {
            background: #ffe1c9;
            color: #f38831;
          }
          &--canceled {
            background: #ffacac;
            color: #ff0000;
          }
        }
        &__form-field {
          @include respond-to(medium) {
            width: 31%;
            padding: 1.8rem 0.5rem 1.8rem 2.5rem;
          }
          &__label {
            font-weight: 700;
            font-size: 1.8rem;
          }
          &__select {
            > div:first-of-type {
              height: 5.5rem;
            }
          }
        }
        &__label {
          font-family: "Abhaya Libre Medium";
          color: #37454d;
          text-align: left;
          font-weight: 500;
          font-size: 1.4rem;
        }
        &__value {
          font-family: Abel;
          font-weight: 500;
          font-size: 1.8rem;
        }
      }
      &__area-container {
        width: 100%;
        &__area {
          background: #556446;
          padding: 0.5rem 1rem;
          font-size: 1.1rem;
          color: white;
          width: fit-content;
        }
      }
    }
    &__button-container {
      width: 93%;
      margin-top: 4rem;
      &__btn {
        align-items: flex-end;
      }
    }
    &__reservation-card {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 1.5rem;
      @include respond-to(medium) {
        width: 65%;
        flex-direction: row;
        padding: 3rem 0rem 3rem 3rem;
      }
      &__item {
        margin-bottom: 1rem;
        @include respond-to(medium) {
          max-width: 20%;
          margin-bottom: 3rem;
          border: 1px solid lightgrey;
          padding: 1rem;
          background: white;
        }
        &__label {
          font-family: "Abhaya Libre Medium";
          color: #37454d;
          text-align: left;
          font-weight: 500;
          font-size: 1rem;
        }
        &__value {
          max-width: 200px;
          font-family: Abel;
          font-weight: 500;
          font-size: 1.8rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__tooltip {
          font-family: Abel;
          font-weight: 500;
          font-size: 1.8rem;
          margin: 1rem;
        }
      }
      &__area-container {
        width: 100%;
        &__area {
          background: #556446;
          padding: 0.5rem 1rem;
          font-size: 1.1rem;
          color: white;
          width: fit-content;
        }
      }
      &__actions {
        align-self: center;

        text-align: right;
        margin-right: 1.5rem;
      }
    }
    &__edit {
      position: absolute;
      right: 1rem;
      @include respond-to(medium) {
        position: static;
        width: 30%;
        align-self: center;
        display: flex;
        justify-content: flex-end;
        text-decoration: none;
        color: #000;
      }
    }
  }
  &__table {
    background-color: white;
    border-radius: 8px;
    max-height: 400px;
    width: 100%;
    overflow-y: auto;

    :global {
      .MuiTableCell-root {
        white-space: nowrap;
        font-size: 1.2rem;
      }
      .MuiTableBody-root .MuiTableRow-root {
        &:hover {
          background-color: $color_7;
        }
      }
    }
  }
  &__unit-type-item {
    background-color: #fff;
    box-shadow: 2px 2px 4px 2px rgba(187, 186, 186, 0.5);
    border-radius: 4px;
    padding: 1.5rem 1.4rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    // cursor: pointer;
    position: relative;
    align-items: center;
    border-left: 5px lightgrey solid;
    @include respond-to(medium) {
      flex-direction: row;
      padding: 1.8rem 0.5rem 1.8rem 2.5rem;
    }
    &--published {
      border-left: $color_1 5px solid;
    }
    &__room-name {
      padding: 0.5rem 1rem;
      border-radius: 8px;
      background-color: #566346;
      color: white;
      white-space: nowrap;
      font-size: 1rem;
      margin-left: 0.5rem;
    }
    &__featured {
      width: 4%;
      padding: 6px 8px;
      margin-top: 0.5rem;
      cursor: pointer;
    }
    &__image-wrapper {
      width: 150px;
      display: flex;
      align-items: center;
      margin-right: 1rem;
      flex-direction: column;
      @include respond-to(medium) {
        height: 120px;
      }
      &__image {
        max-height: 100%;
        max-width: 100%;
        border-radius: 8px;
        &--unpublished {
          filter: grayscale(100%);
        }
      }
    }
    &__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2rem;
      width: 100%;

      @include respond-to(medium) {
        margin-bottom: 0;
        flex: 1;
      }
      &-details {
        &--error {
          font-size: 15px;
          color: $color_1;
          font-weight: 500;
          display: flex;
          align-items: center;
          margin: 1rem 0;
          &_icon {
            width: 2.5rem !important;
            height: auto !important;
            margin-right: 1rem;
          }
        }
        &_property {
          &-details {
            display: flex;
            color: #7b8b94;
            font-size: 1.5rem;
            font-weight: 300;
            text-transform: capitalize;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            @include respond-to(medium) {
              flex-direction: row;
            }

            a {
              display: flex;
              align-items: center;
              margin: 0 0.5rem;
              color: blue;
              &:hover {
                color: darkblue;
              }
            }
            svg {
              margin: 0 0.5rem;
            }
            &-name {
              margin-right: 1rem;
              margin-top: 0.5rem;
              display: flex;
              align-items: center;
            }
            &-area {
              display: flex;
              align-items: center;
              @include respond-to(medium) {
                margin: 0 1rem;
                margin-top: 0.5rem;
              }
            }
          }
          &-availability {
            display: flex;
            flex-direction: row;
            font-size: 1rem;
            font-weight: 300;
            text-transform: capitalize;
            color: $color_5;
            background-color: $color_7;
            border: 1px solid $color_1;
            padding: 1rem;
            border-radius: 2rem;
            margin-top: 1rem;
            justify-content: center;
          }
        }
      }
      &__title {
        font-size: 1.8rem;
        flex: 1;
        font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
        font-weight: 700;
        color: #37454d;
        text-align: left;

        &--untitled {
          color: lightgrey;
        }
        &__created-by {
          margin-top: 0;
          font-size: 1rem;
          color: grey;
          span {
            font-size: 1.2rem;
            font-weight: bold;
          }
        }
      }
      &__icon {
        color: #a2aeb5;
        font-size: 2.5rem !important;
        cursor: pointer;
      }
      button {
        padding-right: 0;
        min-width: unset;
        position: absolute;
        right: 1rem;
        top: 1rem;
        @include respond-to(medium) {
          position: unset;
          right: unset;
          top: unset;
        }
      }
    }
    &__loader {
      align-self: center;
      padding: 10rem 0;
      text-align: center;
    }
    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #7b8b94;
      font-family: "Nunito-Regular", "Nunito", sans-serif;
      font-size: 1.2rem;
      @include respond-to(medium) {
        flex-direction: column;
        align-items: flex-start;
        border-right: 1px solid #dadada;
      }
      &__responses {
        margin-right: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        @include respond-to(medium) {
          order: 1;
          margin-right: unset;
          padding-right: 2.2rem;
          // border-right: 1px solid #dadada;
        }
        &__response-text {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        &--new {
          background-color: #eee;
          color: #484848;
        }
        &__icon {
          font-size: 2rem !important;
          margin-right: 1rem;
        }
        &__number {
          font-weight: bold;
        }
        &__link-text {
          font-size: 1.3rem;
        }
      }
      &__time {
        padding-left: 1rem;
        border-left: 1px solid #dadada;
        font-family: "Nunito-Regular", "Nunito", sans-serif;
        white-space: nowrap;
        overflow: hidden;
        @include respond-to(medium) {
          border-left: unset;
          margin-right: 2rem;
        }
        &--highlighted {
          color: #484848;
          border-radius: 10px;
          background-color: #eeeeee;
          display: flex;
          align-items: center;
          padding: 0.2rem 0.7rem;
          @include respond-to(medium) {
            margin-right: 0;
          }
          img {
            margin-right: 0.5rem;
            height: 100%;
          }
        }
      }
    }
  }
}
