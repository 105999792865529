.react-calendar {
  width: 100%;
  max-width: 100%;
  background: #f4efe5;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  @include respond-to(medium) {
    padding: 1rem;
    width: 36rem;
  }
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  height: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
  align-items: center;
  display: flex;

  @include respond-to(medium) {
    margin-bottom: 0.5em;
  }
}
.react-calendar__navigation__label {
  visibility: hidden;
  cursor: auto;
}
.react-calendar__navigation__arrow {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: #fff;
  font-size: 2rem;
  margin: 1rem !important;
  align-items: center;
  justify-content: center;
}
.react-calendar__navigation button:enabled:hover {
  background-color: rgba(243, 136, 49, 0.39);
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  abbr {
    text-decoration: none !important;
    text-overflow: ellipsis;
  }
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
//   .react-calendar__month-view__days__day--weekend {
//     color: #d10000;
//   }
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75rem;
  background: none;
  font-size: 1rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  width: fit-content !important;
  height: fit-content !important;
  border-radius: 0.5rem;
  box-shadow: 0px 0.578996px 13.04px rgba(0, 14, 51, 0.07);
  abbr {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    @include respond-to(medium) {
      width: fit-content !important;
      height: fit-content !important;
    }
  }
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__month-view__days__day--neighboringMonth {
  background: none;
  border-radius: 0;
  box-shadow: none;
  abbr {
  }
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: rgba(243, 136, 49, 0.39);
  abbr {
  }
}
.react-calendar__tile--now {
  background: rgba(243, 136, 49, 0.39);
  abbr {
  }
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: rgba(243, 136, 49, 0.39);
  abbr {
  }
}
.react-calendar__tile--hasActive {
  background-color: rgba(243, 136, 49, 0.39);
  abbr {
  }
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background-color: rgba(243, 136, 49, 0.39);
  abbr {
  }
}
.react-calendar__tile--active {
  background-color: rgba(243, 136, 49, 0.39);
  color: white;
  abbr {
  }
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: rgba(243, 136, 49, 0.39);
  abbr {
  }
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: rgba(243, 136, 49, 0.39);
  abbr {
  }
}
.react-calendar__tile--active.react-calendar__tile--rangeStart {
  background: #f38831 !important;
  color: white;
  abbr {
  }
}
.react-calendar__tile--active.react-calendar__tile--rangeEnd {
  background: #f38831 !important;
  color: white;
  abbr {
  }
}
