@import "../../..../../../../assets/styles/abstracts/mixins";

.pagination-container {
    display: flex;
    margin: 4rem 0;
    padding: 2rem;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    flex-direction: column;
    gap: 10px;

    @include respond-to(medium) {
        flex-direction: row;
        font-size: 1.3rem;
    }
    &--range {
        color: #f38831;
        margin: 0 2rem;
        &__select {
            display: flex;
            align-items: center;
            text-transform: capitalize;
            margin: 0px 60px;
            font-size: 1rem;
            color: #f38831;
            padding: 0 10px;

            &--input {
                width: 80px;
                margin: 0 10px;

                > div:first-of-type {
                    height: 3.5rem;
                }
                position: relative;
                &__status {
                    position: absolute;
                    padding: 1rem;
                    border-radius: 1rem;
                    top: -2rem;
                    &--expired {
                        background: #e5e5e5;
                        color: #ff0000;
                        display: block;
                    }
                    &--confirmation_pending {
                        background: #f5f7fc;
                        color: #f38831;
                    }
                    &--checked_in {
                        background: #c8ffe4;
                        color: #008000;
                    }
                    &--in_house {
                        background: #008343;
                        color: white;
                    }
                    &--checked_out {
                        background: #686868;
                        color: white;
                    }
                    &--checkin-started {
                        background: #ffe1c9;
                        color: #f38831;
                    }
                    &--canceled {
                        background: #ffacac;
                        color: #ff0000;
                    }
                }
                &__form-field {
                    @include respond-to(medium) {
                        width: 31%;
                        padding: 1.8rem 0.5rem 1.8rem 2.5rem;
                    }
                    &__label {
                        font-weight: 700;
                        font-size: 1.8rem;
                    }
                    &__select {
                        > div:first-of-type {
                            height: 5.5rem;
                        }
                    }
                }
                &__label {
                    font-family: "Abhaya Libre Medium";
                    color: #37454d;
                    text-align: left;
                    font-weight: 500;
                    font-size: 1.4rem;
                }
                &__value {
                    font-family: Abel;
                    font-weight: 500;
                    font-size: 1.8rem;
                }
            }
        }
    }
    ul {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }

    li {
        list-style-type: none;
        a {
            color: #f38831;
        }
        :focus-visible {
            outline: 0;
        }
        a:focus-visible {
            outline: 0;
        }
        a:focus {
            outline: 0;
            text-decoration: none;
        }
        a:hover {
            text-decoration: none;
        }
    }
    :global li.selected {
        border: 1px solid #f38831;
        border-radius: 5px;
        a {
            color: #f38831;
        }
    }
    li a {
        padding: 1rem;
        cursor: pointer;
    }
    li.previous a,
    li.next a,
    li.break a {
        border-color: transparent;
    }
    li.disabled a {
        color: grey;
    }
    li.disable,
    li.disabled a {
        cursor: default;
    }
}
