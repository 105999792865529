@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";
.general-info-container {
  flex: 1;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 8px;
  background-color: white;
  padding: 1.5rem;

  @include respond-to(medium) {
    margin-right: 1rem;
  }

  &__sub-title {
    font-weight: bold;
    color: $color_2;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  &__field {
    margin: 1rem 0;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.9rem;
    button {
      width: 100%;
      height: 4rem;
      @include respond-to(medium) {
        height: unset;
      }
    }
    // div:first-child {
    //   margin-right: 0.9rem;
    // }
    @include respond-to(medium) {
      justify-content: flex-end;
      // margin-left: 4.5rem;
      margin-top: 4rem;
      height: unset;
    }
  }
  &__location {
    margin: 1rem 0;
    height: 70vh;
    width: 97%;
    position: relative;
    &__field {
      position: absolute;
      top: 10px;
      z-index: 10;
      border-radius: 4px;
      border: 1px solid #dedcdc;
      background-color: #ffffff;
      text-align: left;
      height: 3.5rem;
      font-size: 1.6rem;
      font-family: "Nunito-SemiBold", "Nunito SemiBold", "Nunito", sans-serif;
      color: #000000;
      padding: 2rem 1.3rem;
      outline: none;
    }
  }
}
