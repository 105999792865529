@import "../../../../../assets/styles/abstracts/breakpoints";
@import "../../../../../assets/styles/abstracts/mixins";
.add-user-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    min-height: 45vh;
    &__title {
        font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
        color: #37454d;
        text-align: left;
        font-weight: bold;
        font-size: 1.8rem;
    }
    &__description {
        margin-top: 0.5rem;
        font-family: "Nunito", sans-serif;
        color: #37454d;
        text-align: left;
        line-height: normal;
        font-size: 1.8rem;
        max-width: 23rem;
        margin-bottom: 1.1rem;
        @include respond-to(medium) {
            max-width: unset;
        }
    }
    &__gender-container {
        background: #ffffff;
        padding: 2.1rem;
        display: flex;
        position: relative;
        margin-bottom: 1.2rem;
        font-family: "Poppins";
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 150%;
        height: auto;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-width: 2px;
        height: 6rem;
        &__label {
            font-size: 1.8rem;
            color: #525252;
            font-weight: 300;
            width: 40%;
        }
        &__choices {
            display: flex;
            width: 60%;
            &__item {
                color: rgba(0, 0, 0, 0.5);
                font-size: 1.6rem;
                width: 50%;
                margin: 0 0.7rem;
                &__label {
                    cursor: pointer;
                    width: fit-content;
                    &--active {
                        background: #566346;
                        padding: 0.5rem 1rem;
                        color: #fff;
                        border-radius: 3rem;
                    }
                }
            }
        }
    }
    @include respond-to(medium) {
        min-height: unset;
        margin-left: 4.5rem;
        margin-right: 4.5rem;
    }
    textarea {
        font-weight: normal;
        min-height: 5rem;
    }
    &--actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 1.9rem;
        margin-left: 10%;
        margin-right: 10%;
        button {
            height: 4rem;
            @include respond-to(medium) {
                height: unset;
            }
        }
        div:first-child {
            margin-right: 0.9rem;
        }
        @include respond-to(medium) {
            justify-content: flex-end;
            margin-left: 4.5rem;
            margin-right: 3.5rem;
            margin-top: 4rem;
            height: unset;
        }
    }
}
