@import "../../../../../assets/styles/abstracts/breakpoints";
@import "../../../../../assets/styles/abstracts/mixins";
@import "../../../../../assets/styles/abstracts/variables";

.choose-unit-type {
    display: flex;
    flex-direction: column;
    @include respond-to(small) {
        margin-left: 10%;
        margin-right: 10%;
    }
    &__title {
        font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
        color: #37454d;
        text-align: center;
        font-weight: bold;
        font-size: 1.8rem;
    }
    &__search-input {
        display: flex;
        flex-direction: row;
        // margin-top: 1rem;
        // margin-bottom: 1rem;
        // margin-right: 1rem;
        &__btn {
            margin-left: 1rem;
        }
        input {
            padding: 0rem 1.3rem;
            height: 4.2rem;
        }
    }
    &__unit-card-container {
        height: 50vh;
        &__unit-card {
            background-color: #fff;
            box-shadow: 0 2px 4px 0 rgba(187, 186, 186, 0.5);
            border-radius: 4px;
            padding: 1.5rem 1.4rem;
            margin: 1rem 0;
            display: flex;
            flex-direction: column;
            // cursor: pointer;
            align-items: center;
            cursor: pointer;
            @include respond-to(medium) {
                flex-direction: row;
                padding: 1.8rem 0.5rem 1.8rem 2.5rem;
            }
            &__image-wrapper {
                height: 90px;
                width: 120px;
                display: flex;
                align-items: center;
                margin-right: 1rem;
                &__image {
                    max-height: 100%;
                    max-width: 100%;
                    border-radius: 8px;
                }
            }
            &__details {
                flex-direction: row;
                &-availability {
                    display: flex;
                    flex-direction: row;
                    font-size: 1rem;
                    font-weight: 300;
                    text-transform: capitalize;
                    color: $color_5;
                    background-color: $color_7;
                    border: 1px solid $color_1;
                    padding: 1rem;
                    border-radius: 2rem;
                    margin-top: 1rem;
                    justify-content: center;
                }
                label {
                    font-size: 1.2rem;
                    margin: 0 0.5rem;
                }
                span {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    flex-wrap: wrap;
                }
                &__room-name {
                    padding: 0.5rem 1rem;
                    border-radius: 8px;
                    background-color: #566346;
                    color: white;
                    white-space: nowrap;
                    font-size: 1rem;
                    margin-left: 0.5rem;
                    margin-bottom: 0.2rem;
                }
            }
            &__header {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 2rem;
                @include respond-to(medium) {
                    margin-bottom: 0;
                    flex: 1;
                }

                &__title {
                    font-size: 1.8rem;
                    flex: 1;
                    font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
                    font-weight: 700;
                    color: #37454d;
                    text-align: left;
                    &--untitled {
                        color: lightgrey;
                    }
                    &__description {
                        margin-top: 0;
                        font-size: 1rem;
                        color: grey;
                        span {
                            // font-size: 1.2rem;
                            font-weight: bold;
                        }
                    }
                }
            }
            &__room {
                &-details {
                    color: grey;
                    font-weight: 400;
                    margin: 0 3rem;
                    ul {
                        li {
                            list-style: none;
                        }
                    }
                    &__responses {
                        margin-right: 1rem;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        white-space: nowrap;
                        overflow: hidden;
                        @include respond-to(medium) {
                            order: 1;
                            margin-right: unset;
                            padding-right: 2.2rem;
                            // border-right: 1px solid #dadada;
                        }
                        &__response-text {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                        &--new {
                            background-color: #eee;
                            color: #484848;
                        }
                        &__icon {
                            font-size: 2rem !important;
                            margin-right: 1rem;
                        }
                        &__number {
                            font-weight: bold;
                        }
                        &__link-text {
                            font-size: 1.3rem;
                        }
                    }
                }
            }
            &__description {
                display: flex;
                flex-direction: row;
            }
        }
    }
    &__search-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        @include respond-to(small) {
            display: flex;
            flex-direction: row;
        }
    }
    &__actions {
        display: flex;
        margin-top: 2rem;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        justify-content: center;
    }
    &__date--container {
        margin-top: 3rem;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        &-form-field {
            width: 48%;
        }
    }
    &__form-field {
        &--dropdown--container {
            display: flex;
            width: 100%;
        }

        margin: 0.5rem;

        width: 100%;

        @include respond-to(medium) {
            width: 31%;
            padding: 1.8rem 0.5rem 1.8rem 0.5rem;
        }

        &__label {
            font-weight: 700;
            font-size: 1.2rem;
        }
        &__select {
            > div:first-of-type {
                height: 3.5rem;
                @include respond-to(small) {
                    width: 100%;
                }
            }
            position: relative;
            &__status {
                position: absolute;
                padding: 1rem;
                border-radius: 1rem;
                top: -2rem;
                &--expired {
                    background: #e5e5e5;
                    color: #ff0000;
                    display: block;
                }
                &--confirmation_pending {
                    background: #f5f7fc;
                    color: #f38831;
                }
                &--checked_in {
                    background: #c8ffe4;
                    color: #008000;
                }
                &--in_house {
                    background: #008343;
                    color: white;
                }
                &--checked_out {
                    background: #686868;
                    color: white;
                }
                &--checkin-started {
                    background: #ffe1c9;
                    color: #f38831;
                }
                &--canceled {
                    background: #ffacac;
                    color: #ff0000;
                }
            }
            &__form-field {
                @include respond-to(medium) {
                    width: 31%;
                    padding: 1.8rem 0.5rem 1.8rem 2.5rem;
                }
                &__label {
                    font-weight: 700;
                    font-size: 1.8rem;
                }
                &__select {
                    > div:first-of-type {
                        height: 5.5rem;
                    }
                }
            }
            &__label {
                font-family: "Abhaya Libre Medium";
                color: #37454d;
                text-align: left;
                font-weight: 500;
                font-size: 1.4rem;
            }
            &__value {
                font-family: Abel;
                font-weight: 500;
                font-size: 1.8rem;
            }
        }
        &__area-container {
            width: 100%;
            &__area {
                background: #556446;
                padding: 0.5rem 1rem;
                font-size: 1.1rem;
                color: white;
                width: fit-content;
            }
        }
    }
}
