@import "../../../../../assets/styles/abstracts/breakpoints";
@import "../../../../../assets/styles/abstracts/mixins";
@import "../../../../../assets/styles/abstracts/variables";
.double-booking {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 30rem;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    margin-top: 2rem;
    margin-bottom: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    &__no-records {
        display: flex;
        align-items: center;
        justify-content: center;
        color: green;
        flex-direction: column;
        padding-top: 2.5rem;
        width: 50%;
        place-self: center;
        @include respond-to(medium) {
            width: unset;
            height: 100%;
        }
        img {
            max-height: 150px;
        }
    }

    &--table {
        max-height: 300px;
        width: 100%;
        overflow-y: auto;

        :global {
            .MuiTableCell-root {
                white-space: nowrap;
                font-size: 1.2rem;
            }
            .MuiTableBody-root .MuiTableRow-root {
                &:hover {
                    background-color: $color_7;
                }
            }
        }
    }
    &__offer-info {
        width: 90%;
        margin-top: 1.5rem;
        font-size: 2rem;
        background-color: #f4f7fb;
        padding: 1.5rem;
        border-radius: 8px;
        &__header {
            color: $color_2;
            font-weight: bold;
            text-decoration: underline;
            margin-bottom: 1rem;
        }
        &__dates {
            // width: 70%;
            display: flex;
            align-items: flex-start;
            // justify-content: space-between;
            &__item {
                display: flex;
                flex-direction: column;
                flex: 1;
                max-width: 140px;
                &:first-child {
                    // margin-right: 3rem;
                }
                &__header {
                    font-size: 1.4rem;
                    margin-bottom: 0.5rem;
                }
                &__date {
                    font-size: 1.6rem;
                    font-weight: bold;
                }
                &__link {
                    background-color: transparent;
                    border: 0;
                    color: blue;
                    padding: 0;
                    &:hover {
                        color: darkblue;
                    }
                }
                &__seperator {
                    height: 30px;
                    background-color: $color_2;
                    width: 13px;
                }
            }
        }
        &__prices {
            margin-top: 2rem;
            font-size: 1.6rem;
            &__header {
                font-weight: 700;
            }
            &__item {
                display: flex;
                justify-content: space-between;
                &__name {
                    font-weight: 200;
                }
                &__total {
                    font-weight: 700;
                }
            }
        }
        &__channel {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: 3rem;
        }
        &__channel-image {
            width: 3.2rem;
            height: 3.2rem;
            object-fit: contain;
        }
        &__channel-image__bigger {
            width: 6.5rem;
            // height: 4.5rem;
            object-fit: contain;
        }
    }
    &__loader {
        align-self: center;
        padding: 10rem 0;
        text-align: center;
    }
    &--title {
        color: $color_1;
        padding-left: 2rem;
        font-size: 1.5rem;
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        font-weight: 500;
        &_icon {
            width: 2.5rem !important;
            height: auto !important;
            margin-right: 1rem;
        }
    }
    @include respond-to(medium) {
        // padding: 2rem 2rem 2rem 2rem;
        // max-height: 250px;
        align-items: flex-start;
        // background-color: #f5f7fc;
    }
}
