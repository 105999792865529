@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.edit-unit-type-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  @include respond-to(medium) {
    min-height: calc(100vh - 45px);
    align-items: flex-start;
    background-color: #f5f7fc;
    padding: 3rem 2rem 6rem 2rem;
  }

  &__tooltip {
    font-family: Abel;
    font-weight: 500;
    font-size: 1.8rem;
    margin: 1rem;
    line-height: 2rem;
    text-align: center;
    // color: red;
    // background: white;
  }
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    @include respond-to(medium) {
      flex-direction: row;
      align-items: stretch;
    }
    > h1 {
      margin-right: 15px;
      flex: 1;
    }
    &__listed {
      background-color: $background_color_6;
      color: black;
      font-weight: bold;
      padding: 5px 10px 5px 25px;
      border-radius: 8px;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      color: white;
      background-color: $color_2;
      margin-bottom: 2rem;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      @include respond-to(medium) {
        margin-bottom: 0;
        flex-direction: column;
        flex-wrap: nowrap;
        width: unset;
      }
      &__header {
        color: $color_1;
        font-size: 1.2rem;
        margin-top: 0.8rem;
        width: 100%;
      }
    }
    &__channel-sync {
      color: black;
      font-weight: bold;
      padding: 5px 10px 5px 25px;
      border-radius: 8px;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      color: white;
      background-color: darken($color: $color_1, $amount: 10);
      margin-bottom: 2rem;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      @include respond-to(medium) {
        margin-right: 1.5rem;
        margin-bottom: 0;
        flex-direction: column;
        flex-wrap: nowrap;
        width: unset;
        max-width: 35rem;
      }
      &--disabled {
        // pointer-events: none;
        background-color: #666;
      }
      &__header {
        color: white;
        font-size: 1.2rem;
        margin-top: 0.8rem;
        width: 100%;
      }
      &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        &__image {
          width: 7rem;
          height: 7rem;
          font-size: 5.5rem;
          color: $color_2;
          background-color: white;
          border-radius: 5rem;
          padding: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1rem;
          @include transition(all 0.2s ease-in-out);
          &:hover {
            background-color: darken($color: white, $amount: 10);
            color: $color_1;
          }
          &--disabled {
            // pointer-events: none;
            &:hover {
              background-color: white;
            }
          }
          @include respond-to(medium) {
            // flex: 1;
          }
          button {
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
          }
          img {
            width: 7rem;
            height: 7rem;
            font-size: 5.5rem;
            color: $color_2;
            background-color: white;
            border-radius: 5rem;
            // padding: 1.5rem;
          }
        }
        &__content {
          display: flex;
          flex-direction: column;

          display: flex;
          justify-content: flex-start;
          width: 100%;
          margin-bottom: 0.5rem;
          padding-right: 5px;
          align-items: center;
          @include respond-to(medium) {
            flex: 1;
          }

          label {
            padding-right: 1rem;
            // margin-bottom: 1rem;
            font-family: Abhaya Libre Medium;
            font-weight: 500;
            font-size: 1.1rem;
            white-space: nowrap;
            text-transform: uppercase;
            margin-bottom: 0;
            color: rgba(0, 0, 0, 0.7);
          }
          p {
            width: 75%;
            word-wrap: break-word;
            font-size: 14px;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            overflow-x: hidden;
            margin-bottom: 0;
            @include respond-to(medium) {
              white-space: normal;
            }
          }
        }
      }
    }
  }
  &__wrapper {
    width: 100%;
    &__row {
      display: flex;
      flex-direction: column;
      width: 100%;
      &--right {
        display: flex;
        flex-direction: column;
        @include respond-to(medium) {
          width: 50%;
          margin-right: 1rem;
          height: 100%;
        }
      }
      @include respond-to(medium) {
        flex-direction: row;
      }
    }
  }
  &__copy-container {
    background: #fff;
    padding: 1rem;
    border-radius: 5px;
    font-size: 14px;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    width: fit-content;
    &-copy {
      border: none;
      background: #fff;
      position: relative;
      margin: 0 0 0 1rem;
      svg {
        font-size: 20px;
        color: $color_1;
      }
    }
    &-talkbubble {
      width: 5rem;
      height: 34px;
      background: $color_1;
      position: absolute;
      border-radius: 10px;
      left: 36px;
      top: -10px;
      color: #fff;
      justify-content: center;
      display: flex;
      align-items: center;
      &::before {
        content: "";
        position: absolute;
        right: 100%;
        top: 12px;
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-right: 15px solid $color_1;
        border-bottom: 5px solid transparent;
      }
    }
  }
  &__loader {
    align-self: center;
    padding: 10rem 0;
    text-align: center;
  }
  &__field {
    margin: 1rem 0;
  }
  &__sub-title {
    font-weight: bold;
    color: $color_2;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  &__general {
    flex: 1;
    width: 100%;
    margin-top: 1rem;
    padding-bottom: 2rem;

    border-bottom: 1px grey solid;
  }
  &__list-wrapper {
    height: 100%;
    min-height: 400px;
    width: 100%;
    padding-bottom: 2rem;
  }
  &__title {
    color: $color_2;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 1rem 0;
  }
  &__add-new {
    display: flex;
    align-self: flex-end;
    font-size: 3.5rem;
    color: $color_2;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  &__grid-header {
    font-size: 1.3rem;
    // font-weight: bold !important;
    color: $color_2;
  }
  &__unit-type-image {
    height: 100%;
    /* width: 150px; */
    padding: 1rem;
    border-radius: 10px;
  }
}
.edit-unit-type-loader{
  position: absolute;
  top:50%;
  left: 45%;
}
