@import "../../../../assets/styles/abstracts/variables";
@import "../../../../assets/styles/abstracts/mixins";
.side-menu {
    &__link-item {
        &__icon {
            height: 1.6rem;
            margin-right: 2rem;
        }
        &__notification {
            width: 2rem;
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            color: $color_1;
            // position: absolute;
            right: 0;
            top: 0;
            font-size: 1.1rem;
            border-radius: 1.25rem;
        }
        &__new-label {
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color_2;
            color: white;
            // position: absolute;
            right: 0;
            top: 0;
            font-size: 1.1rem;
            border-radius: 4px;
            padding: 0.5rem;
        }
    }
    &__user-actions {
        margin: 2rem 0;
        // margin-left: auto;
        // font-size: 1.4rem;
        // @include respond-to(medium) {
        //     display: none;
        // }
        // @include respond-to(large) {
        //     display: block;
        // }
        &__avatar-wrapper {
            background-color: $color_2;
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            margin-bottom: 0.6rem;
            &__initials {
                color: white;
                font-size: 1.6rem;
                font-weight: bold;
            }
        }

        &__button {
            // height: 30px;
            // border-radius: 2px;
            // border: 1px solid #353e6f;
            // // background-color: $background_color_2;
            // box-sizing: border-box;
            // // font-family: $font_family_1;
            // color: $color_3;
            // text-align: center;
            // margin-right: 30px;
            font-size: 1.1rem;

            &:hover {
                // background-color: $background_color_3;
                // color: $color_4;
            }
        }
    }

    &__user-info {
        margin-left: auto;
        margin-top: auto;
        &__user-data {
            min-width: 75px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-right: 12px;
        }

        &__user-data__username {
            // font-family: $font_family_1;
            font-size: 12px;
        }

        &__user-data__title {
            // font-family: $font_family_1;
            font-size: 10px;
        }

        &__user-avatar {
            min-width: 33px;
            &__user-avatar__img {
                width: 33px;
                height: 33px;
                border-radius: 50%;
            }

            &__user-avatar__img.hidden {
                display: none;
            }
        }
    }
}
