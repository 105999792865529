@import "../../../../assets/styles/abstracts/variables";
@import "../../../../assets//styles//abstracts/mixins";
@import "../../../../assets//styles//abstracts/breakpoints";

.filter-list-container {
    &__expenses-item {
        border-radius: 8px;
        position: relative;
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(187, 186, 186, 0.5);
        border-radius: 4px;
        padding: 1.5rem 1.4rem;
        margin: 1rem 0;
        margin-bottom: 5rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        // cursor: pointer;
        position: relative;
        @include respond-to(medium) {
            flex-direction: row;
            padding: 1.8rem 0.5rem 1.8rem 2.5rem;
        }
        &__form-field {
            @include respond-to(medium) {
                width: 31%;
                padding: 1.8rem 0.5rem 1.8rem 2.5rem;
            }
            &__label {
                font-weight: 700;
                font-size: 1.2rem;
            }
        }
        &__button-container {
            width: 100%;
            margin-top: 1rem;
            display: flex;
            justify-content: flex-end;
            padding: 20px;
            &__btn {
                align-items: flex-end;
                margin: 5px;
            }
        }
    }
}
