@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";
.visuals-container {
  flex: 1;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 8px;
  background-color: white;
  padding: 1.5rem;
  margin-top: 1rem;
  flex-direction: column;

  &__add-new {
    display: flex;
    align-self: flex-end;
    font-size: 3.5rem;
    color: $color_2;
    margin-bottom: 1rem;
    cursor: pointer;
    &--no-data {
      border: 2px solid $color_2;
      margin: auto;
      border-radius: 8px;
      width: fit-content;
      padding: 1.5rem;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
    }
  }

  &__sub-title {
    font-weight: bold;
    color: $color_2;
    font-size: 1.6rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  &__section-title {
    margin-bottom: 2rem;
  }

  &__field {
    margin: 1rem 0;
  }

  &__field-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    &__icon {
      color: $color_2;
      font-size: 2rem;
      margin-right: 2rem;
    }
  }

  &__showroom-container {
    &__item {
      background-color: #f5f7fc;
      border-radius: 8px;
      display: flex;
      align-items: baseline;
      flex-direction: column;
      padding: 1.3rem;
      width: 100%;
      margin-bottom: 2rem;
      @include respond-to(medium) {
        flex-direction: row;
        align-items: center;
      }
      // @include respond-to(large) {
      //   width: 60%;
      // }
      &__title {
        color: #493000;
        font-size: 1.6rem;
        text-align: left;
        margin: 2rem 4rem;
        @include respond-to(medium) {
          text-align: center;
          margin-bottom: unset;
        }
        @include respond-to(large) {
          font-size: 2.4rem;
        }
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.9rem;
    button {
      height: 4rem;
      @include respond-to(medium) {
        height: unset;
      }
    }
    div:first-child {
      margin-right: 0.9rem;
    }
    @include respond-to(medium) {
      justify-content: flex-end;
      margin-left: 4.5rem;
      margin-top: 4rem;
      height: unset;
    }
  }
}
