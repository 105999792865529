@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";
.select-amenities-container {
  flex: 1;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 8px;
  background-color: white;
  padding: 1.5rem;
  margin-top: 1rem;

  &__sub-title {
    font-weight: bold;
    color: $color_2;
    font-size: 1.6rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  &__field {
    margin: 1rem 0;
  }

  &__cards-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0 6rem;
    place-content: space-between;
    flex-flow: wrap;
    align-items: stretch;

    &__loader {
      padding: 10rem 0;
      text-align: center;
    }
    &__message {
      text-align: center;
      padding: 4rem 3rem;
      border: 1px $color_1 solid;
      background-color: lighten($color: $color_1, $amount: 30);
      border-radius: 8px;
    }

    &__card {
      position: relative;
      box-shadow: 0 2px 4px 0 rgba(187, 186, 186, 0.5);
      margin-bottom: 2rem;
      cursor: pointer;
      width: 48%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      border: 1px grey solid;
      border-radius: 8px;
      text-align: center;
      @include respond-to(medium) {
        width: 33%;
      }
      &__checkbox {
        position: absolute;
        top: 1rem;
        left: 1rem;
        color: $color_1;
        font-size: 3rem;
      }
      &__image-container {
        width: 172px;
        max-width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        @include respond-to(medium) {
          height: 116px;
        }
        padding: 0 2rem;
        &__image {
          max-width: 100%;
          border-radius: 8px;
          margin-top: 3rem;
          @include respond-to(medium) {
            margin-top: 0;
          }
        }
      }
      &__name {
        font-weight: bold;
        margin-bottom: 1rem;
      }
    }
  }
}
