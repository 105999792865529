@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.home-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  // height: 100%;
  @include respond-to(medium) {
    height: fit-content;
    min-height: calc(100vh - 45px);
    align-items: flex-start;
    background-color: #f5f7fc;
    padding: 3rem 2rem 3rem 2rem;
  }
  &__title {
    color: $color_2;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 1rem 0;
  }
  &__charts-split {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: stretch;
    justify-content: center;

    @include respond-to(medium) {
      flex-direction: row;
      flex-wrap: wrap;
    }
    &--half {
      width: 100% !important;
      @include respond-to(medium) {
        width: 49% !important;
        margin: 0.5rem;
        display: flex;
        flex-direction: column;
      }
    }
    &--new_line {
      display: flex;
      flex: 1;
      flex-direction: column;
      @include respond-to(medium) {
        flex-direction: column;
      }
    }
    &__chart {
      width: 100%;
      padding: 2rem 0;
      background-color: white;
      border-radius: 8px;
      flex: 1;
      // max-height: 30rem;
      margin-bottom: 3rem;
      &:first-child {
        margin-right: 1.5rem;
      }
      @include respond-to(medium) {
        // width: 50%;
      }
    }
  }
}
