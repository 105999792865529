.otp-component {
    &__input {
        // border: 0;
        border-radius: 15px;
        height: 6rem;
        width: 2rem;
        background-color: $background_color_6;
        // padding: 1.5rem;
        border: 1px solid $background_color_6;
        &--focused {
            outline: none;
            border-color: $color_1;
            // border-width: 1px;
        }
        &--disabled {
            color: grey;
        }
    }
}
