@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";
.plan-info-container {
  flex: 1;
  // width: 50%;
  margin-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 8px;
  background-color: white;
  padding: 1.5rem;

  @include respond-to(medium) {
    margin-right: 1rem;
  }

  &__wrapper {
    width: 100%;
  }
  &__sub-title {
    font-weight: bold;
    color: $color_2;
    font-size: 1.6rem;
    // margin-top: 3rem;
    margin-bottom: 1rem;
  }
  &__separator {
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-top-style: dashed;
  }

  &__field {
    &__label {
      min-width: 230px;
      width: fit-content;
    }
    margin: 1rem 0;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    &-input {
      display: flex;
      align-items: center;
    }
    input {
      width: 40%;
      margin: 0 1rem;
    }

    &__item {
      margin-right: 2rem;
      flex: 1;
    }
  }
  &__rate-plans {
    background-color: $background_color_4;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;

    &__delete-wrapper {
      position: absolute;
      cursor: pointer;
      right: 20px;
      top: 10px;
      z-index: 1;
      font-size: 3rem;
      // border: 4px solid red;
      display: flex;
      align-items: center;
      border-radius: 15px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.9rem;
    button {
      width: 100%;
      height: 4rem;
      @include respond-to(medium) {
        height: unset;
      }
    }
    // div:first-child {
    //   margin-right: 0.9rem;
    // }
    @include respond-to(medium) {
      justify-content: flex-end;
      // margin-left: 4.5rem;
      margin-top: 4rem;
      height: unset;
    }
  }
}
