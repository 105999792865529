@import "../../../../../assets/styles/abstracts/breakpoints";
@import "../../../../../assets/styles/abstracts/mixins";
.choose-appt {
    display: flex;
    flex-direction: column;
    &--actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 1.9rem;
        margin-left: 10%;
        margin-right: 10%;
        button {
            height: 4rem;
            @include respond-to(medium) {
                height: unset;
            }
        }
        div:first-child {
            margin-right: 0.9rem;
        }
        @include respond-to(medium) {
            justify-content: flex-end;
            margin-left: 4.5rem;
            margin-right: 3.5rem;
            margin-top: 4rem;
            height: unset;
        }
    }
    &--discount-actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 1rem;
        button {
            height: 4rem;
            @include respond-to(medium) {
                height: unset;
            }
        }
        div:first-child {
            margin-right: 0.9rem;
        }
        @include respond-to(medium) {
            justify-content: flex-end;
            margin-top: 1rem;
            height: unset;
        }
    }

    &__discount {
     margin-top: 1rem;
     display: flex;
     flex-direction: column;
     gap: 0.5rem;
    }
}
