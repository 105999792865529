@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.reservation-list-container {
  @include respond-to(medium) {
    padding: 3rem 2rem 3rem 2rem;
    min-height: calc(100vh - 45px);
    align-items: flex-start;
    background-color: #f5f7fc;
  }
  &__modal {
    :global(div.MuiDialog-container.MuiDialog-scrollPaper) {
      :global(.MuiDialog-paperWidthSm) {
        max-width: 80rem;
      }
    }
  }

  &__title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: $color_2;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 1rem 0;
    flex-direction: column;
    @include respond-to(medium) {
      flex-direction: row;
    }

    &__actions {
      justify-content: flex-end;
      display: flex;
      // width: 100%;
      button {
        margin-left: 1.5rem;
      }
      // @include respond-to(medium) {
      //   width: 25%;
      // }
    }
  }

  &__loader {
    align-self: center;
    padding: 10rem 0;
    text-align: center;
  }

  &__reservation-item {
    border-radius: 8px;
    position: relative;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(187, 186, 186, 0.5);
    border-radius: 4px;
    padding: 1.5rem 1.4rem;
    margin: 1rem 0;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // cursor: pointer;
    position: relative;
    @include respond-to(medium) {
      flex-direction: row;
      padding: 1.8rem 0.5rem 1.8rem 2.5rem;
    }
    &__status {
      // position: absolute;
      padding: 0.5rem;
      border-radius: 4px;
      font-size: 1rem;
      text-align: center;
      white-space: nowrap;
      margin-bottom: 0.5rem;
      width: fit-content;
      text-transform: capitalize;
      &--highlighted {
        color: #ff0000;
      }
      &--expired {
        background: #e5e5e5;
        color: #ff0000;
      }
      &--confirmation_pending {
        background: #ffd9bc;
        color: #f38831;
      }
      &--checked_in {
        background: #c8ffe4;
        color: #008000;
      }
      &--in_house {
        background: #008343;
        color: white;
      }
      &--checked_out {
        background: #686868;
        color: white;
      }
      &--checkin_started {
        background: #ffe1c9;
        color: #f38831;
      }
      &--pending_check_in {
        background: #686868;
        color: white;
      }
      &--canceled {
        background: #ffacac;
        color: #ff0000;
      }
    }
    &__date-range{
      display: flex;
      gap: 1rem;
    }
    &__form-field {
      @include respond-to(medium) {
        width: 31%;
        padding: 1.8rem 0.5rem 1.8rem 2.5rem;
      }
      &__label {
        font-weight: 700;
        font-size: 1.2rem;
      }
      &__select {
        > div:first-of-type {
          height: 3.5rem;
        }
        position: relative;
        &__status {
          position: absolute;
          padding: 1rem;
          border-radius: 1rem;
          top: -2rem;
          &--expired {
            background: #e5e5e5;
            color: #ff0000;
            display: block;
          }
          &--confirmation_pending {
            background: #f5f7fc;
            color: #f38831;
          }
          &--checked_in {
            background: #c8ffe4;
            color: #008000;
          }
          &--in_house {
            background: #008343;
            color: white;
          }
          &--checked_out {
            background: #686868;
            color: white;
          }
          &--checkin-started {
            background: #ffe1c9;
            color: #f38831;
          }
          &--canceled {
            background: #ffacac;
            color: #ff0000;
          }
        }
        &__form-field {
          @include respond-to(medium) {
            width: 31%;
            padding: 1.8rem 0.5rem 1.8rem 2.5rem;
          }
          &__label {
            font-weight: 700;
            font-size: 1.8rem;
          }
          &__select {
            > div:first-of-type {
              height: 5.5rem;
            }
          }
        }
        &__label {
          font-family: "Abhaya Libre Medium";
          color: #37454d;
          text-align: left;
          font-weight: 500;
          font-size: 1.4rem;
        }
        &__value {
          font-family: Abel;
          font-weight: 500;
          font-size: 1.8rem;
        }
      }
      &__area-container {
        width: 100%;
        &__area {
          background: #556446;
          padding: 0.5rem 1rem;
          font-size: 1.1rem;
          color: white;
          width: fit-content;
        }
      }
    }
    &__button-container {
      margin-top: 4rem;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
      &__btn {
        align-items: flex-end;
        margin-right: 10px;
        align-items: stretch;
      
      }
    }
    &__reservation-card {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 1.5rem;
      @include respond-to(medium) {
        width: 65%;
        flex-direction: row;
        padding: 3rem 0rem 3rem 3rem;
      }
      &__channel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
      }
      &__channel-image {
        width: 4.5rem;
        height: 4.5rem;
        object-fit: contain;
      }
      &__channel-image__bigger {
        width: 7.5rem;
        // height: 4.5rem;
        object-fit: contain;
      }
      &__item {
        margin-bottom: 1rem;
        @include respond-to(medium) {
          max-width: 20%;
          margin-bottom: 3rem;
          border: 1px solid lightgrey;
          padding: 1rem;
          background: white;
        }
        &__label {
          font-family: "Abhaya Libre Medium";
          color: #37454d;
          text-align: left;
          font-weight: 500;
          font-size: 1rem;
        }
        &__value {
          max-width: 200px;
          font-family: Abel;
          font-weight: 500;
          font-size: 1.8rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__tooltip {
          font-family: Abel;
          font-weight: 500;
          font-size: 1.8rem;
          margin: 1rem;
        }
      }
      &__area-container {
        width: 100%;
        &__area {
          background: #556446;
          padding: 0.5rem 1rem;
          font-size: 1.1rem;
          color: white;
          width: fit-content;
        }
      }
      &__actions {
        align-self: center;

        text-align: right;
        margin-right: 1.5rem;
      }
    }
    &__edit {
      position: absolute;
      right: 1rem;
      @include respond-to(medium) {
        position: static;
        width: 30%;
        align-self: center;
        display: flex;
        justify-content: flex-end;
        text-decoration: none;
        color: #000;
      }
    }
  }
  &__noData {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #d9001b;
    flex-direction: column;
    padding-top: 2.5rem;
    width: 100%;
    font-size: 20px;
    img {
      max-width: 500px;
      width: 100%;
    }
    @include respond-to(medium) {
      width: unset;
      height: 100%;
    }
  }
}
