@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";

.request-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__info-wrapper {
        display: flex;
        flex-direction: column;
        @include respond-to(medium) {
            flex-direction: unset;
        }
    }
    &__guest-info {
        font-size: 2rem;
        background-color: #f4f7fb;
        padding: 1.5rem;
        border-radius: 8px;
        flex: 1;
        @include respond-to(medium) {
            margin-right: 1rem;
        }

        &__header {
            color: $color_2;
            font-weight: bold;
            text-decoration: underline;
            margin-bottom: 1rem;
        }
        &__wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 1.1rem;
            color: $color_2;
        }
        &__data {
            margin-left: 1.5rem;
            font-size: 1.6rem;
            color: black;
        }
    }
    &__loader {
        align-self: center;
        padding: 10rem 0;
        text-align: center;
    }

    &__unit-info {
        flex: 1;
        font-size: 2rem;
        background-color: #f4f7fb;
        padding: 1.5rem;
        border-radius: 8px;
        margin-top: 1rem;
        @include respond-to(medium) {
            margin-top: 0;
            margin-left: 1rem;
        }

        &__header {
            color: $color_2;
            font-weight: bold;
            text-decoration: underline;
            margin-bottom: 1rem;
        }
        &__item {
            display: flex;
            flex-direction: column;
            // cursor: pointer;
            align-items: flex-start;
            @include respond-to(large) {
                flex-direction: row;
            }
            &__image-wrapper {
                height: 120px;
                width: 150px;
                display: flex;
                align-items: flex-start;
                margin-right: 1rem;
                &__image {
                    max-height: 100%;
                    max-width: 100%;
                    border-radius: 8px;
                }
            }
            &__header {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 2rem;
                &-details {
                    &_property {
                        &-details {
                            display: flex;
                            flex-direction: column;
                            color: #7b8b94;
                            font-size: 1.5rem;
                            font-weight: 300;
                            text-transform: capitalize;
                            a {
                                display: flex;
                                align-items: center;
                                margin: 0 0.5rem;
                                color: blue;
                                &:hover {
                                    color: darkblue;
                                }
                            }
                            svg {
                                margin: 0 0.5rem;
                            }
                            &-name {
                                margin-right: 1rem;
                                margin-top: 0.5rem;
                                display: flex;
                                align-items: center;
                            }
                            &-area {
                                margin: 1rem 0;
                                margin-top: 0.5rem;
                                display: flex;
                                align-items: center;
                            }
                        }
                        &-availability {
                            display: flex;
                            flex-direction: row;
                            font-size: 1rem;
                            font-weight: 300;
                            text-transform: capitalize;
                            color: $color_5;
                            background-color: $color_7;
                            border: 1px solid $color_1;
                            padding: 1rem;
                            border-radius: 2rem;
                            margin-top: 1rem;
                            justify-content: center;
                        }
                    }
                }
                @include respond-to(medium) {
                    margin-bottom: 0;
                    flex: 1;
                }
                &__title {
                    font-size: 1.6rem;
                    flex: 1;
                    font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
                    // font-weight: 700;
                    color: #37454d;
                    text-align: left;
                    &--untitled {
                        color: lightgrey;
                    }
                    &__created-by {
                        margin-top: 0;
                        font-size: 1rem;
                        color: grey;
                        span {
                            font-size: 1.2rem;
                            font-weight: bold;
                        }
                    }
                }
                &__icon {
                    color: #a2aeb5;
                    font-size: 2.5rem !important;
                    cursor: pointer;
                }
                button {
                    padding-right: 0;
                    min-width: unset;
                }
            }
            &__loader {
                align-self: center;
                padding: 10rem 0;
                text-align: center;
            }
            &__content {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: #7b8b94;
                font-family: "Nunito-Regular", "Nunito", sans-serif;
                font-size: 1.2rem;
                flex-wrap: wrap;
                @include respond-to(medium) {
                    align-items: flex-start;
                    flex-flow: wrap;
                }
                @include respond-to(large) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                &__responses {
                    margin-right: 1rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    white-space: nowrap;
                    overflow: hidden;
                    @include respond-to(medium) {
                        order: 1;
                        margin-right: unset;
                        padding-right: 2.2rem;
                    }
                    &__response-text {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                    &--new {
                        background-color: #eee;
                        color: #484848;
                    }
                    &__icon {
                        font-size: 2rem !important;
                        margin-right: 1rem;
                    }
                    &__number {
                        font-weight: bold;
                    }
                    &__link-text {
                        font-size: 1.3rem;
                    }
                }
                &__time {
                    padding-left: 1rem;
                    border-left: 1px solid #dadada;
                    font-family: "Nunito-Regular", "Nunito", sans-serif;
                    white-space: nowrap;
                    overflow: hidden;
                    @include respond-to(medium) {
                        border-left: unset;
                        margin-right: 2rem;
                    }
                    &--highlighted {
                        color: #484848;
                        border-radius: 10px;
                        background-color: #eeeeee;
                        display: flex;
                        align-items: center;
                        padding: 0.2rem 0.7rem;
                        @include respond-to(medium) {
                            margin-right: 0;
                        }
                        img {
                            margin-right: 0.5rem;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    &__offer-info {
        margin-top: 1.5rem;
        font-size: 2rem;
        background-color: #f4f7fb;
        padding: 1.5rem;
        border-radius: 8px;
        &__header {
            color: $color_2;
            font-weight: bold;
            text-decoration: underline;
            margin-bottom: 1rem;
        }
        &__dates {
            // width: 70%;
            display: flex;
            // justify-content: space-between;
            &__item {
                display: flex;
                flex-direction: column;
                &:first-child {
                    margin-right: 3rem;
                }
                &__header {
                    font-size: 1.4rem;
                    margin-bottom: 0.5rem;
                }
                &__date {
                    font-size: 1.6rem;
                    font-weight: bold;
                }
            }
        }
        &__prices {
            margin-top: 2rem;
            font-size: 1.3rem;
            &__header {
                font-weight: 700;
            }
            &__item {
                display: flex;
                justify-content: space-between;
                &__name {
                    font-weight: 200;
                    flex: 1;
                }
                &__discount {
                    color: red;
                    font-weight: 700;
                    text-decoration: line-through;
                    margin-right: 1rem;
                }
                &__total {
                    font-weight: 700;
                }
                &__sum {
                    border-top: 1px solid grey;
                    font-weight: 700;
                }
            }
        }
    }
}
