$color_1: #f38831;
$color_2: #566346;
$color_3: #353e6f;
$color_4: #ffffff;
$color_5: #37454d;
$color_6: #009191;
$color_7: #ffd59f;
$color_8: #6b6b6b;
$color_9: #c8c8c8;

$font_family_1: "Raleway";
$background_color_1: white;
$background_color_2: rgba(53, 62, 111, 0);
$background_color_3: #68719d;
$background_color_4: #fff1cd;
$background_color_5: #f0f0f0;
$background_color_6: #dddbd0;

// colors
$primary-color: #00bfbf;
$success-color: #2de595;
$warning-color: #dda22c;
$danger-color: #d9001b;
