@import "../../../../assets/styles/abstracts/_variables.scss";

.unauthorized {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  font-family: $font_family_1;

  &__title {
    font-size: 2.6em;
    color: #5e77ac;
  }
  &__action-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 2em;
  }

  &__action-button {
    margin-right: 1.5rem;
    background-color: $color_1;
    border-radius: 4px;
    padding: 1.5rem 2.5rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    border: 1px solid $color_1;
    &:hover {
      background-color: darken($color: $color_1, $amount: 10);
    }
    a {
      text-decoration: none;
      color: white;
    }
  }
}
