@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";
.rooms-container {
  flex: 1;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 8px;
  background-color: white;
  padding: 1.5rem;
  margin-top: 1rem;

  &__sub-title {
    font-weight: bold;
    color: $color_2;
    font-size: 1.6rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  &__field {
    margin: 1rem 0;
  }
  &__card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &__card {
      margin-right: 2rem;
      background-color: #f5f7fc;
      padding: 2rem;
      min-width: 25rem;
      margin-bottom: 2rem;
      min-height: 20rem;
      &__label {
        font-family: Abhaya Libre Medium;
        font-weight: 500;
        font-size: 1.2rem;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.7);
      }
      &__value {
        font-family: Abel;
        font-size: 1.4rem;
        margin-bottom: 1rem;
      }
      &--add-new {
        background-color: #00008b;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f5f7fc;
        font-size: 1.4;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
}
