.gender-picker {
    &__text-wrapper {
        display: flex;
        flex-direction: column;
        text-transform: none;
        &__description {
            font-family: "Nunito-Regular", "Nunito", sans-serif;
            color: #77878f;
            text-align: left;
            font-size: 1rem;
            text-transform: none;
        }
    }
}
