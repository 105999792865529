@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.feedback-list {
    :global {
      .MuiTableContainer-root {
        width: 98%;
        margin: auto;      }
    }
  &__title {
    font-size: 1.8rem;
    flex: 1;
    font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
    font-weight: 700;
    color: #37454d;
    text-align: left;
    margin: 3rem 1rem;
    &--untitled {
      color: lightgrey;
    }
    &__created-by {
      margin-top: 0;
      font-size: 1rem;
      color: grey;
      span {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
  }

    &__noData {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #d9001b;
      flex-direction: column;
      padding-top: 2.5rem;
      width: 100%;
      font-size: 20px;
      img {
        max-width: 500px;
        width: 100%;
      }
      @include respond-to(medium) {
        width: unset;
        height: 100%;
      }
    }
    &__loader {
      align-self: center;
      padding: 10rem 0;
      text-align: center;
    }
    &__clickable-cell{
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
}
