@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.amenities-list-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  @include respond-to(medium) {
    min-height: calc(100vh - 45px);
    align-items: flex-start;
    background-color: #f5f7fc;
    padding: 3rem 2rem 3rem 2rem;
  }
  &__amenity-item {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(187, 186, 186, 0.5);
    border-radius: 4px;
    padding: 1.5rem 1.4rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    // cursor: pointer;
    align-items: center;
    @include respond-to(medium) {
      flex-direction: row;
      padding: 1.8rem 0.5rem 1.8rem 2.5rem;
    }
    &__image-wrapper {
      height: 120px;
      width: 150px;
      display: flex;
      align-items: center;
      margin-right: 1rem;
      &__image {
        max-height: 100%;
        max-width: 100%;
        border-radius: 8px;
      }
    }
    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 2rem;
      @include respond-to(medium) {
        margin-bottom: 0;
        flex: 1;
      }
      &__title {
        font-size: 1.8rem;
        flex: 1;
        font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
        font-weight: 700;
        color: #37454d;
        text-align: left;
        &--untitled {
          color: lightgrey;
        }
        &__created-by {
          margin-top: 0;
          font-size: 1rem;
          color: grey;
          span {
            font-size: 1.2rem;
            font-weight: bold;
          }
        }
      }
      &__icon {
        color: #a2aeb5;
        font-size: 2.5rem !important;
        cursor: pointer;
      }
      button {
        padding-right: 0;
        min-width: unset;
      }
    }
    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #7b8b94;
      font-family: "Nunito-Regular", "Nunito", sans-serif;
      font-size: 1.2rem;
      &__responses {
        margin-right: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        @include respond-to(medium) {
          order: 1;
          margin-right: unset;
          padding-right: 2.2rem;
          border-right: 1px solid #dadada;
        }
        &__response-text {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        &--new {
          background-color: #eee;
          color: #484848;
        }
        &__icon {
          font-size: 2rem !important;
          margin-right: 1rem;
        }
        &__number {
          font-weight: bold;
        }
        &__link-text {
          font-size: 1.3rem;
        }
      }
      &__time {
        padding-left: 1rem;
        border-left: 1px solid #dadada;
        font-family: "Nunito-Regular", "Nunito", sans-serif;
        white-space: nowrap;
        overflow: hidden;
        @include respond-to(medium) {
          border-left: unset;
          margin-right: 2rem;
        }
        &--highlighted {
          color: #484848;
          border-radius: 10px;
          background-color: #eeeeee;
          display: flex;
          align-items: center;
          padding: 0.2rem 0.7rem;
          @include respond-to(medium) {
            margin-right: 0;
          }
          img {
            margin-right: 0.5rem;
            height: 100%;
          }
        }
      }
    }
  }
  &__list-wrapper {
    height: 100%;
    min-height: 400px;
    width: 100%;
    padding-bottom: 2rem;
  }
  &__title {
    color: $color_2;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 1rem 0;
  }
  &__add-new {
    display: flex;
    align-self: flex-end;
    font-size: 3.5rem;
    color: $color_2;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  &__grid-header {
    font-size: 1.3rem;
    // font-weight: bold !important;
    color: $color_2;
  }
  &__amenity-image {
    height: 100%;
    /* width: 150px; */
    padding: 1rem;
    border-radius: 10px;
  }
}
