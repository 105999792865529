@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";
.custom-price-container {
    flex: 1;
    margin-top: 1rem;
    padding-bottom: 2rem;
    border-radius: 8px;
    background-color: #566346ed;
    padding: 1.5rem;

    @include respond-to(medium) {
        width: 100%;
        margin-right: 1rem;
    }
    Button {
        margin-top: 10px;
    }
    &-title {
        font-weight: bold;
        color: white;
        font-size: 1.6rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        Button {
            padding: 7px !important;
            margin-top: 0;
        }
    }
    &__content {
        &-image {
            display: block;
            margin: auto;
            max-width: 100%;
        }
    }
    &--table {
        max-height: 300px;
        overflow-y: auto;

        :global {
            .MuiTableCell-root {
                white-space: nowrap;
                font-size: 1.2rem;
            }
            .MuiTableBody-root .MuiTableRow-root {
                &:hover {
                    background-color: $color_7;
                }
            }
        }
    }
}
