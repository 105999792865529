#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

body {
  display: flex;
  flex-direction: row;
  height: 100vh;
  // overflow-y: scroll;
  // -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #898889;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}

.ios-toolbar-gone {
  transform: translateY(-25px);
  // overflow-y: scroll;
  // height: 100%;
  // -webkit-overflow-scrolling: touch;
  // height: 90vh;
}

.body-content {
  background-color: #f5f7fc;

  // @include respond-to(medium) {
  //   background-color: #f5f7fc;
  // }

  // display: flex;
  // flex-direction: column;
  overflow-y: auto;

  // width: calc(100% - 160px);
  height: 100%;

  // margin-left: 150px;
  &__container {
    // height: 100%;
    margin: 0 1rem;
    min-height: calc(100% - 45px);
    height: 100%;
    @include respond-to(medium) {
      margin: 0;
      // margin-left: 50px;
    }
    @include respond-to(large) {
      margin-left: 240px;
    }
  }
}

.body-content.no-side {
  margin-left: 0px;
  width: 100%;
}

@include keyframes(slide-down) {
  0% {
    margin-top: -70px;
  }
  100% {
    margin-top: 0;
  }
}

.absolute {
  position: absolute;
  top: 50px;

  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}
.alert-global {
  font-size: 1.2rem !important;
}
.mb-28 {
  margin-bottom: 24px;
}
.mt-24 {
  margin-top: 24px;
}

.stackedAccordion.MuiAccordion-root {
  background-color: #f2f2f2;
  // width: 100%;
  padding-bottom: 10px;
  outline: 0;
  flex-direction: column;
  text-align: center;
  text-align: -webkit-center;
  align-items: center;
  @include respond-to(medium) {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:before {
    display: none;
  }
  // .MuiAccordionDetails-root {
  //   position: relative;
  //   border-radius: 12px;
  //   box-shadow: 0px 0px 5px 0px rgba(130, 130, 130, 0.23);
  //   // margin: 0 1.7rem;
  //   flex-direction: column !important;
  //   padding: 0;
  //   text-align: center;
  //   text-align: -webkit-center;
  //   width: 100%;
  //   // min-width: calc(100% - 3.2rem);
  //   padding-bottom: 3.5rem;
  //   justify-content: flex-start;
  //   background-color: white;
  //   @include respond-to(medium) {
  //     width: 100%;
  //     margin: 0 1.7rem;
  //     max-width: 36rem;
  //     box-shadow: none;
  //     margin: 0;
  //     height: 100%;
  //   }
  // }
  &.Mui-expanded {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0;
    place-items: center;
    flex: 1;
    // max-height: 50vh;
    overflow: auto;
    outline: 0;
    justify-content: center;
    flex-direction: column;
    @include respond-to(medium) {
      align-items: center;
      flex: unset;
    }
  }
  // .MuiCollapse-container {
  //   width: 100%;
  // }
  .MuiCollapse-entered {
    width: 100%;
    min-width: 100%;
    @include respond-to(medium) {
      // eslint-disable-next-line
      width: stretch;
      // eslint-disable-next-line
      width: -webkit-stretch;
      text-align: center;
      text-align: -webkit-center;
    }
  }
  .MuiAccordionSummary-root {
    padding: 0;
    width: calc(100vw - 3.2rem);
    align-items: center;
    background-color: transparent;
    @include respond-to(medium) {
      width: 100%;
    }
  }

  .MuiCollapse-hidden {
    display: none;
  }
  .MuiCollapse-wrapperInner {
    text-align: center;
    text-align: -webkit-center;
  }

  .MuiAccordionSummary-content {
    margin: 0;
    font-size: 2rem;
    background-color: white;
    border-radius: 8px;
    color: #333333;
    padding: 1.6rem 2rem;
    align-items: center;
    @include respond-to(medium) {
      max-width: 36rem;
      padding: 1.6rem 1rem;
      background-color: #f4f5f9;
    }
    .edit-ico {
      width: 25px;
      height: 20px;
    }
    .alert-ic {
      width: 20px;
      height: 17px;
      margin-right: 8px;
    }
  }
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.red-hover {
  button:hover {
    box-shadow: inset 1px 1px 5px 0px #d9001b;
    background: transparent;
    border: 1px solid #d9001b;
  }
}

// .MuiTooltip-popper .tooltip {
//   // font-size: 1.5rem !important;
//   padding: 1rem !important;

//   // position: absolute;
//   // will-change: unset !important;
//   // top: unset !important;
//   // left: 50% !important;
//   // bottom: 0 !important;
//   // transform: translateX(-50%) !important;
// }

.MuiAlert-message {
  font-family: "Nunito", sans-serif;
  font-size: 1.1rem;
}

.MuiTabs-scrollable {
  margin: 1rem 0 !important;
  border-radius: 8px;
  background-color: white;
  @include respond-to(medium) {
    margin-bottom: 3rem !important;
  }
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 300px;
}

.swiper-wrapper {
  display: inline-flex;
}
.MuiAccordionSummary-root.Mui-focused,
.MuiAccordionSummary-root.Mui-focusVisible {
  background-color: transparent !important;
}

// .MuiListItemText-root {
//   @include respond-to(medium) {
//     display: none;
//   }
//   @include respond-to(large) {
//     display: block;
//   }
// }
.MuiTableCell-root {
  font-size: 1.6rem !important;
}

.MuiTypography-body1 {
  font-size: 1.6rem !important;
  font-family: Nunito !important;
}

.MuiSelect-selectMenu {
  background-color: white !important;
  // height: 3.5rem !important;
  font-size: 1.6rem !important;
  font-family: "Nunito-SemiBold", "Nunito SemiBold", "Nunito", sans-serif !important;
}

.MuiMenuItem-root {
  font-size: 1.6rem !important;
  font-family: "Nunito-SemiBold", "Nunito SemiBold", "Nunito", sans-serif !important;
}
.e-schedule .e-timeline-month-view .e-resource-left-td {
  width: 100px;
  @include respond-to(medium) {
    width: 200px;
  }
}

.highcharts-credits {
  display: none !important;
}