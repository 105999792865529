@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.reset-password-container {
  display: flex;
  justify-content: center;
  height: 100%;
  @include respond-to(medium) {
    align-items: center;
    height: unset;
    background-color: #f5f7fc;
    padding-top: 5rem;
  }
  &__wrapper {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-left: 2.9rem;
    padding-right: 2.9rem;
    padding-top: 6rem;
    height: 100%;
    align-items: flex-start;
    width: 100%;
    @include respond-to(medium) {
      margin: unset;
      height: unset;
      max-width: 500px;
      border-radius: 4px;
      border: 1px solid #f3f3f3;
      padding: 3rem 3.5rem;
      justify-content: center;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.18);
    }
    &__user-icon {
      @include respond-to(medium) {
        margin-top: 4.2rem;
      }
      align-self: flex-start;
    }
    &__title {
      font-size: 3rem;
      width: 80%;
      margin-top: 1.6rem;
    }
    &__sub_title {
      font-size: 1.8rem;
      margin-bottom: 3.4rem;
      width: 80%;
    }
    &__label {
      font-size: 1.6rem;
      font-family: "Nunito-SemiBold", "Nunito SemiBold", "Nunito", sans-serif;
    }
    &__error-message {
      color: red;
      text-align: left;
      align-self: flex-start;
      :last-child {
        margin-left: 0.5rem;
      }
    }
    &__submit-button {
      margin-top: 3.8rem;
      margin-bottom: 1.5rem;
      position: relative;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    &__registration {
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
      margin-bottom: 3.9rem;
      padding-bottom: 2rem;
      &__message {
        font-size: 1.6rem;
        margin-right: 0.5rem;
      }
      &__link {
        text-decoration: underline;
        font-size: 1.6rem;
        cursor: pointer;
        color: $color_1;
      }
    }
  }
  &__illustration {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    padding: 0 1.8rem;
    @include respond-to(medium) {
      flex: 0.6;
      height: unset;
      padding-bottom: 3rem;
    }
    &__image {
      height: 25rem;
      @include respond-to(medium) {
        height: unset;
        width: 100%;
      }
    }
    &__message {
      text-align: center;
      padding: 0 1rem;
      margin-bottom: 1.1rem;
    }
    &__action-buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      div:first-child {
        margin-bottom: 0.9rem;
        @include respond-to(medium) {
          margin-bottom: 0;
          margin-right: 0.9rem;
        }
      }
      @include respond-to(medium) {
        flex-direction: row;
      }
    }
  }
}
