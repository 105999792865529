.react-datepicker__input-container input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #dedcdc;
    background-color: #ffffff;
    text-align: left;
    height: 3.5rem;
    font-size: 1.6rem;
    font-family: "Nunito-SemiBold", "Nunito SemiBold", "Nunito", sans-serif;
    color: #000000;
    padding: 2rem 1.3rem;
    outline: none;
}
.react-datepicker {
    font-size: 1em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name, .react-datepicker__day {
    width: 1.6em;
    line-height: 1.6em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
  }
  .react-datepicker__navigation--previous {
    left: 1em;
  }
  .react-datepicker__navigation--next {
    right: 1em;
  }