@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.unit-types-list-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  @include respond-to(medium) {
    min-height: calc(100vh - 45px);
    align-items: flex-start;
    background-color: #f5f7fc;
    padding: 3rem 2rem 3rem 2rem;
  }
  &__search {
    border-radius: 8px;
    position: relative;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(187, 186, 186, 0.5);
    border-radius: 4px;
    padding: 1.5rem 1.4rem;
    margin: 1rem 0;
    // margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // cursor: pointer;
    position: relative;
    width: 100%;
    font-size: 1.2rem;
    align-items: center;
    @include respond-to(medium) {
      flex-direction: row;
      padding: 1.8rem 0.5rem 1.8rem 2.5rem;
    }
    &__button-container {
      height: -webkit-fill-available;
      padding: 1.8rem 0.5rem 0 2.5rem;
      display: flex;
      align-items: flex-start;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;
    }
    &__form-field {
      @include respond-to(medium) {
        width: 31%;
        padding: 1.8rem 0.5rem 1.8rem 2.5rem;
      }
      &__label {
        font-weight: 700;
        font-size: 1.2rem;
      }
      &__select {
        > div:first-of-type {
          height: 3.5rem;
        }
        position: relative;
        &__status {
          position: absolute;
          padding: 1rem;
          border-radius: 1rem;
          top: -2rem;
          &--expired {
            background: #e5e5e5;
            color: #ff0000;
            display: block;
          }
          &--confirmation_pending {
            background: #f5f7fc;
            color: #f38831;
          }
          &--checked_in {
            background: #c8ffe4;
            color: #008000;
          }
          &--in_house {
            background: #008343;
            color: white;
          }
          &--checked_out {
            background: #686868;
            color: white;
          }
          &--checkin-started {
            background: #ffe1c9;
            color: #f38831;
          }
          &--canceled {
            background: #ffacac;
            color: #ff0000;
          }
        }
        &__form-field {
          @include respond-to(medium) {
            width: 31%;
            padding: 1.8rem 0.5rem 1.8rem 2.5rem;
          }
          &__label {
            font-weight: 700;
            font-size: 1.8rem;
          }
          &__select {
            > div:first-of-type {
              height: 5.5rem;
            }
          }
        }
        &__label {
          font-family: "Abhaya Libre Medium";
          color: #37454d;
          text-align: left;
          font-weight: 500;
          font-size: 1.4rem;
        }
        &__value {
          font-family: Abel;
          font-weight: 500;
          font-size: 1.8rem;
        }
      }
      &__button-container {
        width: 93%;
        margin-top: 4rem;
        &__btn {
          align-items: flex-end;
        }
      }
    }
  }
  &__add-unit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &__unit-type-item {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(187, 186, 186, 0.5);
    border-radius: 4px;
    padding: 1.5rem 1.4rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    // cursor: pointer;
    position: relative;
    align-items: center;
    @include respond-to(medium) {
      flex-direction: row;
      padding: 1.8rem 0.5rem 1.8rem 2.5rem;
    }
    &__actions {
      display: flex;
      flex-wrap: wrap;

      :globalf {
        .MuiButton-text {
          padding: 0;
          gap: 2;
        }
      }
    }
    &--published {
      border-left: $color_1 5px solid;
    }
    &__booking-mode {
      position: absolute;
      font-size: 3rem;
      bottom: 2rem;
      right: 1rem;
      color: white;
      background-color: $color_1;
      display: flex;
      border-radius: 1.5rem;
      &__tooltip {
        font-family: Abel;
        font-weight: 500;
        font-size: 1.8rem;
        margin: 1rem;
      }
    }
    &__room-name {
      padding: 0.5rem 1rem;
      border-radius: 8px;
      background-color: #566346;
      color: white;
      white-space: nowrap;
      font-size: 1rem;
      margin-left: 0.5rem;
    }
    &__featured {
      padding: 6px 8px;
      margin-top: 0.5rem;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__image-wrapper {
      width: 150px;
      display: flex;
      align-items: center;
      margin-right: 1rem;
      flex-direction: column;
      position: relative;
      @include respond-to(medium) {
        height: 120px;
      }
      &__image {
        max-height: 100%;
        /* max-width: 100%; */
        border-radius: 8px;
        width: 150px;
        height: 105px;
        object-fit: cover;
        &--unpublished {
          filter: grayscale(100%);
        }
      }
    }
    &__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2rem;
      width: 100%;

      @include respond-to(medium) {
        margin-bottom: 0;
        flex: 1;
      }
      &-details {
        &--error {
          font-size: 15px;
          color: $color_1;
          font-weight: 500;
          display: flex;
          align-items: center;
          margin: 1rem 0;
          &_icon {
            width: 2.5rem !important;
            height: auto !important;
            margin-right: 1rem;
          }
        }
        &_property {
          &-details {
            display: flex;
            color: #7b8b94;
            font-size: 1.5rem;
            font-weight: 300;
            text-transform: capitalize;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            @include respond-to(medium) {
              flex-direction: row;
            }

            a {
              display: flex;
              align-items: center;
              margin: 0 0.5rem;
              color: blue;
              &:hover {
                color: darkblue;
              }
            }
            svg {
              margin: 0 0.5rem;
            }
            &-name {
              margin-right: 1rem;
              margin-top: 0.5rem;
              display: flex;
              align-items: center;
            }
            &-area {
              display: flex;
              align-items: center;
              @include respond-to(medium) {
                margin: 0 1rem;
                margin-top: 0.5rem;
              }
            }
          }
          &-availability {
            display: flex;
            flex-direction: row;
            font-size: 1rem;
            font-weight: 300;
            text-transform: capitalize;
            color: $color_5;
            background-color: $color_7;
            border: 1px solid $color_1;
            padding: 1rem;
            border-radius: 2rem;
            margin-top: 1rem;
            justify-content: center;
          }
        }
      }
      &__title {
        font-size: 1.8rem;
        flex: 1;
        font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
        font-weight: 700;
        color: #37454d;
        text-align: left;

        &--untitled {
          color: lightgrey;
        }
        &__created-by {
          margin-top: 0;
          font-size: 1rem;
          color: grey;
          span {
            font-size: 1.2rem;
            font-weight: bold;
          }
        }
      }
      &__icon {
        color: #a2aeb5;
        font-size: 2.5rem !important;
        cursor: pointer;
      }
      button {
        padding-right: 0;
        min-width: unset;
        position: absolute;
        right: 1rem;
        top: 1rem;
        @include respond-to(medium) {
          position: unset;
          right: unset;
          top: unset;
        }
      }
    }
    &__loader {
      align-self: center;
      padding: 10rem 0;
      text-align: center;
    }
    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #7b8b94;
      font-family: "Nunito-Regular", "Nunito", sans-serif;
      font-size: 1.2rem;
      @include respond-to(medium) {
        flex-direction: column;
        align-items: flex-start;
        border-right: 1px solid #dadada;
      }
      &__responses {
        margin-right: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        @include respond-to(medium) {
          order: 1;
          margin-right: unset;
          padding-right: 2.2rem;
          // border-right: 1px solid #dadada;
        }
        &__response-text {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        &--new {
          background-color: #eee;
          color: #484848;
        }
        &__icon {
          font-size: 2rem !important;
          margin-right: 1rem;
        }
        &__number {
          font-weight: bold;
        }
        &__link-text {
          font-size: 1.3rem;
        }
      }
      &__time {
        padding-left: 1rem;
        border-left: 1px solid #dadada;
        font-family: "Nunito-Regular", "Nunito", sans-serif;
        white-space: nowrap;
        overflow: hidden;
        @include respond-to(medium) {
          border-left: unset;
          margin-right: 2rem;
        }
        &--highlighted {
          color: #484848;
          border-radius: 10px;
          background-color: #eeeeee;
          display: flex;
          align-items: center;
          padding: 0.2rem 0.7rem;
          @include respond-to(medium) {
            margin-right: 0;
          }
          img {
            margin-right: 0.5rem;
            height: 100%;
          }
        }
      }
    }
  }
  &__list-wrapper {
    width: 100%;
    padding-bottom: 2rem;
  }
  &__title {
    color: $color_2;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 1rem 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  &__add-new {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 3.5rem;
    color: $color_2;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  &__search-input {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    &__btn {
      margin-left: 1rem;
    }
  }
  &__grid-header {
    font-size: 1.3rem;
    // font-weight: bold !important;
    color: $color_2;
  }
  &__unit-type-image {
    height: 100%;
    /* width: 150px; */
    padding: 1rem;
    border-radius: 10px;
  }
  &__noData {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #d9001b;
    flex-direction: column;
    padding-top: 2.5rem;
    width: 100%;
    font-size: 20px;
    img {
      max-width: 500px;
      width: 100%;
    }
    @include respond-to(medium) {
      width: unset;
      height: 100%;
    }
  }
}
