@import "../../../../../assets/styles/abstracts/breakpoints";
@import "../../../../../assets/styles/abstracts/mixins";
@import "../../../../../assets/styles/abstracts/variables";
.add-guest {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;
  min-height: 45vh;
  &__title {
    font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
    color: #37454d;
    text-align: left;
    font-weight: bold;
    font-size: 1.8rem;
  }
  &__description {
    margin-top: 0.5rem;
    font-family: "Nunito", sans-serif;
    color: #37454d;
    text-align: left;
    line-height: normal;
    font-size: 1.8rem;
    max-width: 23rem;
    margin-bottom: 1.1rem;
    @include respond-to(medium) {
      max-width: unset;
    }
  }
  &__gender-container {
    background: #ffffff;
    padding: 2.1rem;
    display: flex;
    position: relative;
    margin-bottom: 1.2rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 150%;
    height: auto;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-width: 2px;
    height: 6rem;
    &__label {
      font-size: 1.8rem;
      color: #525252;
      font-weight: 300;
      width: 40%;
    }
    &__choices {
      display: flex;
      width: 60%;
      &__item {
        color: rgba(0, 0, 0, 0.5);
        font-size: 1.6rem;
        width: 50%;
        margin: 0 0.7rem;
        &__label {
          cursor: pointer;
          width: fit-content;
          &--active {
            background: #566346;
            padding: 0.5rem 1rem;
            color: #fff;
            border-radius: 3rem;
          }
        }
      }
    }
  }
  @include respond-to(medium) {
    min-height: unset;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  textarea {
    font-weight: normal;
    min-height: 5rem;
  }
  &--actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.9rem;
    margin-left: 10%;
    margin-right: 10%;
    button {
      height: 4rem;
      @include respond-to(medium) {
        height: unset;
      }
    }
    div:first-child {
      margin-right: 0.9rem;
    }
    @include respond-to(medium) {
      justify-content: flex-end;
      margin-left: 4.5rem;
      margin-right: 3.5rem;
      margin-top: 4rem;
      height: unset;
    }
  }
  &__search {
    display: flex;
    flex-direction: column;
    width: 100%;
    &--input {
      // position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      button {
        padding: 1rem !important;
        height: 100%;
        margin-left: 0.5rem;
      }
    }
  }
}
.user-info-container {
  flex: 1;
  margin-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 8px;
  background-color: white;
  padding: 1.5rem;

  @include respond-to(medium) {
    width: 100%;
    margin-right: 1rem;
  }
  &-title {
    font-weight: bold;
    color: $color_2;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  &-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2rem 0;
    border: 1px solid #dedcdc;
    border-radius: 6px;
    padding: 2rem;
    &-info {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 20px;
      padding-right: 5px;
      &--hint {
        color: $warning-color;
        margin: 1rem;
        display: flex;
        justify-content: center;
      }

      label {
        width: 25%;
        font-weight: 400;
        font-size: 16px;
      }
      p {
        width: 75%;
        word-wrap: break-word;
        font-size: 14px;
      }
      @include respond-to(medium) {
        // width: 50%;
      }
    }
    &-image {
      display: block;
      margin: auto;
    }
  }
}
