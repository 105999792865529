@import "../../../../assets/styles/abstracts/variables";
.input-text {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dedcdc;
  background-color: #ffffff;
  text-align: left;
  height: 3.5rem;
  font-size: 1.6rem;
  font-family: "Nunito-SemiBold", "Nunito SemiBold", "Nunito", sans-serif;
  color: #000000;
  padding: 2rem 1.3rem;
  outline: none;
  &:focus {
    border-color: $color_1;
    border-width: 2px;
  }
  &--error {
    outline-color: red;
    border: 1px solid red;
    color: red;
  }
  // &__error-message-wrapper {
  //   min-height: 3.5rem;
  // }
  &__error-message {
    color: red;
    margin-top: 0;
    font-size: 1.3rem;
    margin-bottom: 0;
  }
}
