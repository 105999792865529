@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.feedback-details {
    :global {
      .MuiTableContainer-root {
        width: 98%;
        margin: auto;      }
    }
  &__title{
    font-size: 1.8rem;
    font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
    font-weight: 700;
    color: #37454d;
    text-align: left;
    margin: 3rem 1rem;
  }
  &__question{
    font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
    font-weight: 600 !important;
    color: $color_2;
    text-align: left;
  }
  &__loader {
    display: flex;
    justify-content: center;
    padding: 10rem 0;
    width: 100%;
    text-align: center;
  }
  &__noData {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #d9001b;
    flex-direction: column;
    padding-top: 2.5rem;
    width: 100%;
    font-size: 20px;
    img {
      max-width: 500px;
      width: 100%;
    }
    @include respond-to(medium) {
      width: unset;
      height: 100%;
    }
  }
}
