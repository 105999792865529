#root {
    .MuiToggleButtonGroup-groupedHorizontal {
        align-items: center;
        display: flex;
        font-size: 1.3rem;
        &.Mui-selected {
            background-color: #f1f4f5;
            color: #37454d;
        }
        &:not(:first-child) {
            border-left: 1px solid rgba(0, 0, 0, 0.12);
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            &.Mui-selected {
                border-left: 1px solid rgb(253, 99, 1);
                background-color: #f1f4f5;
                color: #37454d;
            }
        }
    }
    //.MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
    //    // background-color: red;
    //    border-left: 1px solid rgba(0, 0, 0, 0.12);
    //    border-top-left-radius: 7px;
    //    border-bottom-left-radius: 7px;
    //    &.Mui-selected {
    //        border-left: 1px solid rgb(253, 99, 1);
    //        background-color:#f1f4f5 ;
    //        color: #37454d;
    //    }
    //}
    .MuiToggleButtonGroup-groupedHorizontal:not(:last-child) {
        border-radius: 7px;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
    }
}
