@import "../../../../assets/styles/abstracts/variables";
@import "../../../../assets//styles//abstracts/mixins";
.calendar {
  position: relative;
  &__booked {
    abbr {
      text-decoration: line-through;
      text-decoration-thickness: 2px;
    }
  }
  &__input {
    font-size: 2rem;
    background: #fff;
    padding: 0rem;
    color: rgba(0, 0, 0, 0.6);
    b {
      color: #000;
    }
    &--date {
      &-view {
        border: 1px solid #dedcdc;
        background-color: #ffffff;
        text-align: left;
        height: 3.5rem;
        font-size: 1.6rem;
        color: #000000;
        padding: 2rem 1.3rem;
        border-radius: 4px;
        display: flex;
        align-items: center;
      }
    }
  }
  &__calendar-container {
    position: absolute;
    z-index: 1;
    // margin: 1px;
    width: 100%;
    @include respond-to(medium) {
      width: 36rem;
    }
    &--calender-view {
      :global(.react-calendar) {
        width: 100% !important;
      }
    }
    &__price-tag {
      font-size: 0.8rem;
      font-weight: 100;
      &--custom-price {
        color: $color_1;
      }
    }
    &__tile-content {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      width: 3rem !important;
      height: 2.5rem !important;
      border-radius: 0.5rem;
      box-shadow: 0px 0.578996px 13.04px rgb(0 14 51 / 7%);
    }
    &__dropdowns-container {
      display: flex;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      top: 3%;
      @include respond-to(medium) {
        top: 4%;
      }
      &__item {
        padding-right: 0.5rem;
      }
    }
  }
}
