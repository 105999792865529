@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.reservation-edit-container {
  &-loader {
    position: absolute;
    top: 50%;
    left: 57%;
  }

  @include respond-to(medium) {
    padding: 3rem 2rem 3rem 2rem;
    min-height: calc(100vh - 45px);
    align-items: flex-start;
    background-color: #f5f7fc;
  }
  &__title {
    color: $color_2;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    &__header {
      flex: 1;
      display: flex;
      align-items: center;
    }
    &__select {
      color: #fff;
      width: 35%;
      font-size: 1.5rem;

      margin-left: 1rem;
      margin-right: 1rem;
      @include respond-to(medium) {
        width: 25%;
        margin-left: 1rem;
      }
    }
    &__reactivate {
      color: #fff;
      width: 55%;
      font-size: 1.5rem;
      @include respond-to(medium) {
        width: 20%;
        margin-right: 1rem;
      }
      button {
        display: flex;
        white-space: nowrap;
        padding: 1.25rem 2rem !important;
        align-items: center;
        justify-content: center;
      }
    }
    &__booking-mode {
      font-size: 3rem;
      color: white;
      background-color: $color_1;
      display: flex;
      border-radius: 1.5rem;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      &__tooltip {
        font-family: Abel;
        font-weight: 500;
        font-size: 1.8rem;
        margin: 1rem;
      }
      &__text {
        font-size: 1.4rem;
        margin-right: 1rem;
      }
    }
    &__currency {
      font-size: 3rem;
      color: white;
      background-color: $color_2;
      display: flex;
      border-radius: 1.5rem;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      padding-left: 0.5rem;
      &__tooltip {
        font-family: Abel;
        font-weight: 500;
        font-size: 1.8rem;
        margin: 1rem;
      }
      &__text {
        font-size: 1.4rem;
        margin-right: 1rem;
      }
    }
    &__channel-wrapper {
      display: flex;
      flex-direction: row;
    }
    &__channel {
      font-size: 3rem;
      color: white;
      display: flex;
      border-radius: 1.5rem;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      &__tooltip {
        font-family: Abel;
        font-weight: 500;
        font-size: 1.8rem;
        margin: 1rem;
      }
      &__text {
        font-size: 1.4rem;
        margin-right: 1rem;
      }
      &__image {
        width: 2.5rem;
        height: 2.5rem;
        object-fit: contain;
      }
    }
  }
  &__reservation-item {
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(187, 186, 186, 0.5);
    border-radius: 8px;
    padding: 1.5rem 1.4rem;
    margin: 1rem 0;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // cursor: pointer;
    &--out {
      color: red;
      font-weight: bold;
    }
    @include respond-to(medium) {
      flex-direction: row;
      padding: 1.8rem 1.5rem 1.8rem 2.5rem;
      align-items: flex-start;
    }
    &__title {
      color: $color_2;
      font-size: 2.5rem;
      font-weight: bold;
      margin: 1rem 0;
      display: flex;
      flex-direction: row;
      &__select {
        color: #fff;
        width: 55%;
        font-size: 1.5rem;
        @include respond-to(medium) {
          width: 20%;
          margin-left: 1rem;
        }
      }
      &__reactivate {
        color: #fff;
        width: 55%;
        font-size: 1.5rem;
        @include respond-to(medium) {
          width: 20%;
          margin-right: 1rem;
        }
        &__button {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  &__status-details {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @include respond-to(medium) {
      flex-direction: row;
    }
    &__dates {
      @extend .reservation-edit-container__reservation-item;
      justify-content: space-between;
      flex-direction: row;
      width: fit-content;
      align-items: center;
      border-radius: 8px;
      @include respond-to(medium) {
        margin-right: 1rem;
      }
      &__label {
        margin-bottom: 1rem;
        font-family: Abhaya Libre Medium;
        font-weight: 500;
        font-size: 1.1rem;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.7);
        :first-child {
          margin-right: 1rem;
        }
        :last-child {
          margin-left: 1rem;
        }
      }
      &__value {
        font-family: Abel;
        font-size: 1.6rem;
      }
    }
    &__payments {
      @extend .reservation-edit-container__reservation-item;
      justify-content: space-between;
      flex-direction: row;
      flex: 1;
      border-radius: 8px;
      @include respond-to(medium) {
        margin-right: 1rem;
      }

      &__wrapper {
        align-self: stretch;
        display: flex;
        flex-direction: column;
      }
      &__label {
        margin-bottom: 1rem;
        font-family: Abhaya Libre Medium;
        font-weight: 500;
        font-size: 1.1rem;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.7);
      }
      &__value {
        font-family: Abel;
        font-size: 1.6rem;
        display: flex;
        &--out {
          color: red;
          font-weight: bold;
        }
      }
      &__icon {
        // width: 15px;
        // height: 15px;
        margin: 0;
      }
    }
    &__duration {
      @extend .reservation-edit-container__reservation-item;
      justify-content: space-between;
      flex-direction: row;
      width: fit-content;
      border-radius: 8px;
      @include respond-to(medium) {
        margin-right: 1rem;
      }
      &__label {
        margin-bottom: 1rem;
        margin-right: 1rem;
        font-family: Abhaya Libre Medium;
        font-weight: 500;
        font-size: 1.1rem;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.7);
      }
      &__value {
        font-family: Abel;
        font-size: 1.6rem;
      }
    }
  }
  &__details-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    @include respond-to(large) {
      flex-direction: row;
    }
  }
  &__log-details-wrapper {
    display: flex;
    flex-direction: column;
    
    @include respond-to(large) {
      width: 25%;
    }
  }
  &__log-details {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #ffe0c8;
    padding: 1rem;
    padding: 1.8rem 1.5rem 1.8rem 2.5rem;
    height: fit-content;
  }
  &__reservation-details {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    @include respond-to(medium) {
      flex-direction: row;
      flex-flow: wrap;
    }
    @include respond-to(large) {
      width: 75%;
      flex-wrap: wrap;
    }
    &__unit {
      @extend .reservation-edit-container__reservation-item;
      padding: 0;
      width: 100%;
      border-radius: 8px;
      @include respond-to(medium) {
        width: calc(40% - 1rem);
        margin-right: 1rem;
      }
      &__image {
        width: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        object-fit: cover;
        @include respond-to(medium) {
          height: 25rem;
        }
      }
      &__area-container {
        width: 100%;
        padding: 1.8rem 1.5rem 1.8rem 2.5rem;
        &__area {
          background: #ffe1c8;
          border-radius: 0.5rem;
          padding: 0.5rem 1rem;
          color: #f38831;
          width: fit-content;
        }
      }
      &__details {
        padding: 1.8rem 1.5rem 1.8rem 2.5rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        > div {
          width: 50%;
          margin-bottom: 1.5rem;
        }
        &__label {
          padding-right: 1rem;
          margin-bottom: 1rem;
          font-family: Abhaya Libre Medium;
          font-weight: 500;
          font-size: 1.1rem;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.7);
        }
        &__value {
          font-family: Abel;
          font-size: 1.6rem;
          display: flex;
          align-items: center;
          &--out {
            color: red;
            font-weight: bold;
          }
          &__link {
            margin-bottom: 0;
            color: darkblue;
            text-decoration: underline;
            cursor: pointer;
            display: inline-block;
            padding-right: 1rem;
            background: transparent;
            border: 0;
            svg {
              vertical-align: middle;
            }
          }
        }
      }
      &__pass-key {
        border-top: 1px dashed lightgrey;
        margin: 0 1.5rem 0 2.5rem;
        padding: 1.8rem 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 3.4rem;
        // > div {
        //   margin-bottom: 1.5rem;
        // }
        &__header {
          align-self: flex-start;
          font-size: 1.8rem;
          font-family: "Nunito", sans-serif;
          color: $color_2;
          font-weight: bold;
          text-align: left;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.7);
          // margin-bottom: 1.5rem;
        }
        &__image {
          align-self: center;
          margin: 1.8rem 0;
        }
        &__separator {
          font-size: 2.5rem;
          margin: auto;
        }
        &__actions {
          display: flex;
          flex-direction: row;
          margin: 1.5rem 0;
          button {
            width: fit-content;
            height: stretch;
            &:first-child {
              margin-right: 1.5rem;
            }
          }
        }
      }
    }
    &__contacts {
      display: block;
      width: 100%;
      display: flex;
      flex-direction: column;
      @include respond-to(medium) {
        width: calc(60% - 1rem);
        margin-right: 1rem;
      }
    }
    &__contact-container {
      @extend .reservation-edit-container__reservation-item;
      display: block;
      @include respond-to(medium) {
        margin-top: 0;
      }
      &__header {
        font-size: 1.5rem;
        // font-weight: bold;
        font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
        color: $color_1;
        text-align: left;
      }
      &__sub-header {
        color: $color_9;
        font-size: 1.1rem;
      }
    }
    &__guest-container {
      @extend .reservation-edit-container__reservation-item;
      display: block;
      flex: 1;
      // width: 100%;
      // @include respond-to(medium) {
      //   width: 60%;
      //   margin-right: 1rem;
      // }
      &__add-guest {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      &__tabs-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &__tabs {
          height: 10%;
        }
        &__add {
          cursor: pointer;
          font-size: 3.5rem;
        }
      }
      &__guest-wrapper {
        display: flex;
        flex-direction: column;
        @include respond-to(medium) {
          flex-direction: row;
          flex-wrap: wrap;
        }
      }
      &__guest {
        width: 100%;
        margin-top: 1.5rem;
        @include respond-to(medium) {
          width: 50%;
          padding-right: 1rem;
        }
        &--full-row {
          @include respond-to(medium) {
            width: 100%;
          }
        }
        &__label {
          margin-bottom: 1rem;
          font-family: Abhaya Libre Medium;
          font-weight: 500;
          font-size: 1.1rem;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.7);
        }
        &__value {
          font-family: Abel;
          font-size: 1.6rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__upload-container {
          font-family: Abhaya Libre Medium;
          font-weight: 500;
          font-size: 1.1rem;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.7);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        &__dropzone {
          display: none !important;
        }

        &__link-container {
          border: 1px grey solid;
          border-radius: 4px;
          margin-bottom: 1rem;
          display: flex;
          justify-content: center;
          padding: 1rem;
          text-decoration: none;
          &__image {
            width: 60px;
            margin-right: 1rem;
          }
          &__details {
            display: flex;
            flex-direction: column;
            width: calc(100% - 60px);
            &__filename {
              flex: 1;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              color: black;
              font-size: 1.6rem;
              font-family: Abhaya Libre Medium;
              font-weight: 500;
              text-transform: uppercase;
              color: rgba(0, 0, 0, 0.7);
            }
            &__file-id {
              flex: 1;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              color: lightgrey;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
  &__payments {
    display: flex;
    flex-direction: column;
    width: 100%;
    hr {
      width: 100%;
    }
    &__header {
      color: $color_2;
      font-size: 2.5rem;
      font-weight: bold;
      margin: 1rem 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      &__add {
        cursor: pointer;
        font-size: 3.5rem;
      }
    }
    &__wrapper {
      align-self: stretch;
      display: flex;
      flex-direction: column;
    }
    &__list {
      margin-bottom: 5rem;
      width: 100%;
    }
    &__link {
      background-color: $color_1;
      color: white;
      border-top-right-radius: 18px;
      border-top-left-radius: 18px;
      > button {
        margin: 1rem 0 1rem 1rem;
      }

      &__send {
        align-self: flex-end;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        &__btn {
          display: flex;
          align-items: center;
          border-top-right-radius: 12px;
          background-color: white;
          color: $color_1;
          border: 2px solid $color_1;
          padding: 1rem 0.5rem 1rem 1rem;
          height: -webkit-fill-available;

          > svg {
            margin-left: 0.6rem;
          }
        }
      }
    }
    &__copy-container {
      font-size: 14px;
      // margin: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      > span {
        margin: 1rem 0 1rem 1rem;
      }
      &-copy {
        border: none;
        position: relative;

        background-color: $color_1;
        svg {
          font-size: 20px;
          color: white;
        }
      }
      &-talkbubble {
        width: 5rem;
        height: 34px;
        background: white;
        position: absolute;
        border-radius: 10px;
        left: 46px;
        top: -5px;
        color: $color_1;
        justify-content: center;
        display: flex;
        align-items: center;
        &::before {
          content: "";
          position: absolute;
          right: 100%;
          top: 12px;
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-right: 15px solid white;
          border-bottom: 5px solid transparent;
        }
      }
    }
    &__item {
      // background-color: #fff;
      width: 100%;
      padding-right: 4em;
      border-right: 1px lightgrey solid;
      // margin-right: 2rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: stretch;
      // cursor: pointer;
      @include respond-to(medium) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        place-items: center;
        align-items: flex-start;
      }
      &__label {
        margin-bottom: 1rem;
        font-family: Abhaya Libre Medium;
        font-weight: 500;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.7);
        flex: 1;
      }
      &__value {
        font-family: Abel;
        font-size: 1.6rem;
        flex: 1;
        display: flex;
        align-items: center;
        min-height: 4.5rem;
        &__select {
          min-width: 11rem;
        }
        &__image {
          width: 50px;
        }
      }
      &__details {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 2rem;
        background-color: #eaf9da;
        &__item {
          width: 100%;
          display: flex;
          flex-direction: row;
          border-bottom: 1px lightgrey solid;
          padding-bottom: 2rem;
          margin-bottom: 2rem;
          &__content {
            width: 20rem;
            &__action {
              display: flex;;
            }
          }
          &__title {
            width: 20rem;
            margin-bottom: 1rem;
            font-family: Abhaya Libre Medium;
            font-weight: 500;
            font-size: 1.4rem;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.7);
          }
        }
      }
      &__paid-details {
        hr {
          width: 100%;
        }
        width: 100%;
        display: flex;
        flex-direction: column;
        &__label {
          @extend .reservation-edit-container__payments__item__label;
          display: inline-block;
          margin-right: 2rem;
        }
        &__value {
          @extend .reservation-edit-container__payments__item__value;
          font-weight: 700;
          display: inline-block;
        }
        &__link {
          @extend .reservation-edit-container__payments__item__paid-details__value;
          @extend .reservation-edit-container__reservation-details__unit__details__value__link;
        }
      }
      &__generate {
        display: flex;
        flex-direction: column;

        &-link {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;

          a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 80%;
          }
          &__container {
            border-radius: 5px;
            font-size: 14px;
            margin: 1rem 0;
            display: flex;
            align-items: center;
            &-copy {
              border: none;
              background: transparent;
              position: relative;
              margin: 0 0 0 1rem;
              svg {
                font-size: 20px;
                color: $color_1;
              }
            }
            &-talkbubble {
              width: 5rem;
              height: 34px;
              background: $color_1;
              position: absolute;
              border-radius: 10px;
              left: 36px;
              top: -10px;
              color: #fff;
              justify-content: center;
              display: flex;
              align-items: center;
              &::before {
                content: "";
                position: absolute;
                right: 100%;
                top: 12px;
                width: 0;
                height: 0;
                border-top: 7px solid transparent;
                border-right: 15px solid $color_1;
                border-bottom: 5px solid transparent;
              }
            }
          }
        }
        &-btn {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
    &__attempt {
      @extend .reservation-edit-container__payments__item;
      border-right: 0;
    }
  }
}
