@import "../../../../../assets/styles/abstracts/breakpoints";
@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";
.global-calendar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__reservation-item {
        position: relative;
        background-color: white;
        margin: 1rem 10%;
        // margin-bottom: 4rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        // cursor: pointer;
        position: relative;
        @include respond-to(medium) {
            flex-direction: row;
        }
        &__actions {
            display: flex;
            margin-top: 2rem;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
            justify-content: center;
            @include respond-to(medium) {
                width: unset;
                margin-top: 0;
                align-items: center;
                padding: 3rem 0.5rem 1.8rem 0.5rem;
            }
        }
        &__search-input {
            display: flex;
            input {
                padding: 1.8rem 1.3rem;
            }

            &__btn {
                margin-left: 1rem;
                &-clear {
                    background-color: transparent;
                    border: none;
                    position: absolute;
                    right: 3px;
                    color: #000;
                    font-weight: 500;
                }
            }
        }
        &__status {
            // position: absolute;
            padding: 0.5rem;
            border-radius: 4px;
            font-size: 1rem;
            text-align: center;
            white-space: nowrap;
            margin-bottom: 0.5rem;
            width: fit-content;
            text-transform: capitalize;
            &--highlighted {
                color: #ff0000;
            }
            &--expired {
                background: #e5e5e5;
                color: #ff0000;
            }
            &--confirmation_pending {
                background: #ffd9bc;
                color: #f38831;
            }
            &--checked_in {
                background: #c8ffe4;
                color: #008000;
            }
            &--in_house {
                background: #008343;
                color: white;
            }
            &--checked_out {
                background: #686868;
                color: white;
            }
            &--pending_check_in {
                background: #686868;
                color: white;
            }
            &--checkin_started {
                background: #ffe1c9;
                color: #f38831;
            }
            &--canceled {
                background: #ffacac;
                color: #ff0000;
            }
        }
        &__form-field {
            @include respond-to(medium) {
                width: 25%;
                padding: 1.8rem 0.5rem 1.8rem 0.5rem;
            }
            &-container {
                button {
                    background-color: transparent;
                    color: black;
                }
            }
            &-search {
                display: flex;
                flex-direction: row;
                position: relative;
                align-items: center;
            }
            &__label {
                font-weight: 700;
                font-size: 1.2rem;
            }
            &__select {
                > div:first-of-type {
                    height: 3.5rem;
                }
                position: relative;
                &__status {
                    position: absolute;
                    padding: 1rem;
                    border-radius: 1rem;
                    top: -2rem;
                    &--expired {
                        background: #e5e5e5;
                        color: #ff0000;
                        display: block;
                    }
                    &--confirmation_pending {
                        background: #f5f7fc;
                        color: #f38831;
                    }
                    &--checked_in {
                        background: #c8ffe4;
                        color: #008000;
                    }
                    &--in_house {
                        background: #008343;
                        color: white;
                    }
                    &--checked_out {
                        background: #686868;
                        color: white;
                    }
                    &--checkin-started {
                        background: #ffe1c9;
                        color: #f38831;
                    }
                    &--canceled {
                        background: #ffacac;
                        color: #ff0000;
                    }
                }
                &__form-field {
                    @include respond-to(medium) {
                        width: 31%;
                        padding: 1.8rem 0.5rem 1.8rem 2.5rem;
                    }
                    &__label {
                        font-weight: 700;
                        font-size: 1.8rem;
                    }
                    &__select {
                        > div:first-of-type {
                            height: 5.5rem;
                        }
                    }
                }
                &__label {
                    font-family: "Abhaya Libre Medium";
                    color: #37454d;
                    text-align: left;
                    font-weight: 500;
                    font-size: 1.4rem;
                }
                &__value {
                    font-family: Abel;
                    font-weight: 500;
                    font-size: 1.8rem;
                }
            }
            &__area-container {
                width: 100%;
                &__area {
                    background: #556446;
                    padding: 0.5rem 1rem;
                    font-size: 1.1rem;
                    color: white;
                    width: fit-content;
                }
            }
        }
        &__button-container {
            margin-bottom: 4rem;
            margin-top: 4rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: row;
            flex: 1;
            &__btn {
                align-items: flex-end;
            }
        }
        &__reservation-card {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin-top: 1.5rem;
            @include respond-to(medium) {
                width: 65%;
                flex-direction: row;
                padding: 3rem 0rem 3rem 3rem;
            }
            &__item {
                margin-bottom: 1rem;
                @include respond-to(medium) {
                    max-width: 20%;
                    margin-bottom: 3rem;
                    border: 1px solid lightgrey;
                    padding: 1rem;
                    background: white;
                }
                &__label {
                    font-family: "Abhaya Libre Medium";
                    color: #37454d;
                    text-align: left;
                    font-weight: 500;
                    font-size: 1rem;
                }
                &__value {
                    max-width: 200px;
                    font-family: Abel;
                    font-weight: 500;
                    font-size: 1.8rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                &__tooltip {
                    font-family: Abel;
                    font-weight: 500;
                    font-size: 1.8rem;
                    margin: 1rem;
                }
            }
            &__area-container {
                width: 100%;
                &__area {
                    background: #556446;
                    padding: 0.5rem 1rem;
                    font-size: 1.1rem;
                    color: white;
                    width: fit-content;
                }
            }
            &__actions {
                align-self: center;

                text-align: right;
                margin-right: 1.5rem;
            }
        }
        &__edit {
            position: absolute;
            right: 1rem;
            @include respond-to(medium) {
                position: static;
                width: 30%;
                align-self: center;
                display: flex;
                justify-content: flex-end;
                text-decoration: none;
                color: #000;
            }
        }
    }
    &__unit-type {
        display: flex;
        align-items: center;
        flex-direction: column;
        @include respond-to(medium) {
            flex-direction: row;
        }
        // min-height: 100px;
        // justify-content: center;
    }
    &__unit-type-description {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        align-items: center;
        justify-content: center;
        max-width: 144px;
        width: 100%;
        &__tooltip {
            font-family: Abel;
            font-weight: 500;
            font-size: 1.8rem;
            margin: 1rem;
        }
    }
    &__unit-type-image {
        width: 40px;
        height: 40px;
        border-radius: 8px;
    }
    &__lazy-loader {
        position: absolute;
        width: 100px;
        height: 100px;
        z-index: 1;
        left: 50%;
        top: 40%;
        position: absolute;
        padding: 10rem 0;
        text-align: center;
    }
    &__loader {
        align-self: center;
        padding: 10rem 0;
        text-align: center;
    }
    &__reservation {
        display: flex;
        min-height: 40px;
        justify-content: flex-start;
        align-items: flex-start;
        color: white;

        &__link {
            background-color: transparent;
            border: 0;
            color: $color_1;
            text-decoration: underline;
            padding: 0;
            &:hover {
                color: white;
            }
        }
    }
}
