@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";

.sortable-gallery {
    position: relative;
    margin-right: 2rem;
    margin-bottom: 2rem;
    &_remove-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: white;
        top: -15px;
        right: -15px;
        border: 3px solid red;
        background-color: red;
        display: flex;
        position: absolute;
        z-index: 1;
        cursor: pointer;

        span {
            font-weight: bold;
            margin: auto;
            display: flex;
        }
    }
    // &__delete{
    //     width:100
    // }
    &_photo-container {
        display: flex;
        // justify-content: space-evenly;
        flex-wrap: wrap;
        flex-direction: row;
    }

    &_photo-frame {
        display: flex;
        box-sizing: content-box;
        position: relative;
        background: #ffffff;
        cursor: grab;
        border-radius: 4px;
        box-shadow: rgb(0 0 0 / 20%) 0 3px 3px -2px, rgb(0 0 0 / 14%) 0 3px 4px 0, rgb(0 0 0 / 12%) 0 1px 8px 0;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 10px;
    }
}
