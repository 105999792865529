@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.reservation-create-container {
  display: block;
  &__title {
    font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
    color: #37454d;
    text-align: center;
    font-weight: bold;
    font-size: 1.8rem;
  }
  &__details-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
    > div {
      width: 50%;
      margin-right: 2rem;
    }
    &__select {
      min-width: 11rem;
      margin-bottom: 1.5rem;
    }
    &__textarea {
      font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
      color: #37454d;
      text-align: left;
      font-size: 1.8rem;
      margin-top: 1rem;
    }
    &__item {
      margin-bottom: 1.4rem;
      &__monthly-rates {
        &__prices {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          &__item {
            flex: 1;
            border: 2px solid $color_2;
            margin-right: 1rem;
            padding: 2rem 1rem;
            font-weight: 200;
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            justify-content: center;
            align-items: center;
            position: relative;
            &:last-child {
              margin-right: 0;
            }
            &__discount-label {
              position: absolute;
              top: 4px;
              background-color: $background_color_2;
              color: white;
              padding: 2px 4px;
              right: 4px;
              font-size: 1rem;
            }
            &--selected {
              border: 2px solid $color_1;
            }
            &__duration {
              border-bottom: 1px solid $color_2;
              text-align: center;
              width: 100%;
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              font-size: 1.1rem;
              padding-bottom: 1rem;
              &__count {
                font-size: 2.3rem;
                font-weight: bold;
              }
            }
            &__amount {
              margin-top: 1rem;
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              font-size: 1.4rem;
              &__count {
                font-size: 1.8rem;
              }
            }
          }
        }
      }
    }
    &__payment-details {
      margin-bottom: 3rem;
      &__header {
        font-weight: 100;
        margin-bottom: 2rem;
      }
      &__dates {
        width: 70%;
        display: flex;
        justify-content: space-between;
        &__item {
          display: flex;
          flex-direction: column;
          &__header {
            font-weight: 100;
            margin-bottom: 0.5rem;
          }
        }
      }
      &__prices {
        margin-top: 2rem;
        &__header {
          font-weight: 700;
        }
        &__item {
          display: flex;
          justify-content: space-between;
          &__name {
            font-weight: 200;
          }
          &__total {
            font-weight: 700;
          }
        }
      }
    }
    &--actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 1.9rem;
      margin-left: 10%;
      margin-right: 10%;
      button {
        height: 4rem;
        @include respond-to(medium) {
          height: unset;
        }
      }
      div:first-child {
        margin-right: 0.9rem;
      }
      @include respond-to(medium) {
        justify-content: flex-end;
        margin-left: 4.5rem;
        margin-right: 3.5rem;
        margin-top: 4rem;
        height: unset;
      }
    }
    textarea {
      font-weight: normal;
      min-height: 5rem;
      width: 100%;
      margin-top: 2rem;
    }
  }
}
