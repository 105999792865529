@import "../../../assets/styles/abstracts/_variables.scss";

.unauthorized-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  font-family: $font_family_1;
  height: 100%;

  &__title {
    font-size: 2.6em;
    color: $danger-color;
  }
}
