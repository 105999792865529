@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";
.assign-partner-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    @include respond-to(medium) {
        min-height: calc(100vh - 45px);
        align-items: flex-start;
        background-color: #f5f7fc;
        padding: 3rem 2rem 3rem 2rem;
    }
    &__assign-user {
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(187, 186, 186, 0.5);
        border-radius: 4px;
        padding: 1.5rem 1.4rem;
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        // cursor: pointer;
        align-items: center;
        @include respond-to(medium) {
            flex-direction: row;
            padding: 1.8rem 0.5rem 1.8rem 2.5rem;
        }
        &__image-wrapper {
            height: 120px;
            width: 150px;
            display: flex;
            align-items: center;
            margin-right: 1rem;
            &__image {
                max-height: 100%;
                max-width: 100%;
                border-radius: 8px;
            }
        }
        &__header {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 2rem;
            @include respond-to(medium) {
                margin-bottom: 0;
                flex: 1;
            }
            &__tt {
                font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
                color: #37454d;
                text-align: left;
                font-weight: bold;
                font-size: 1.8rem;
            }
            &__title {
                font-size: 1.8rem;
                flex: 1;
                font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
                font-weight: 700;
                color: #37454d;
                text-align: left;
                &--untitled {
                    color: lightgrey;
                }
                &__created-by {
                    margin-top: 0;
                    font-size: 1rem;
                    color: grey;
                    span {
                        font-size: 1.2rem;
                        font-weight: bold;
                    }
                }
            }
            &__icon {
                color: #a2aeb5;
                font-size: 2.5rem !important;
                cursor: pointer;
            }
            button {
                padding-right: 0;
                min-width: unset;
            }
        }
        &__content {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #7b8b94;
            font-family: "Nunito-Regular", "Nunito", sans-serif;
            font-size: 1.2rem;
            &__responses {
                margin-right: 1rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                white-space: nowrap;
                overflow: hidden;
                @include respond-to(medium) {
                    order: 1;
                    margin-right: unset;
                    padding-right: 2.2rem;
                    border-right: 1px solid #dadada;
                }
                &__response-text {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
                &--new {
                    background-color: #eee;
                    color: #484848;
                }
                &__icon {
                    font-size: 2rem !important;
                    margin-right: 1rem;
                }
                &__number {
                    font-weight: bold;
                }
                &__link-text {
                    font-size: 1.3rem;
                }
            }
            &__time {
                padding-left: 1rem;
                border-left: 1px solid #dadada;
                font-family: "Nunito-Regular", "Nunito", sans-serif;
                white-space: nowrap;
                overflow: hidden;
                @include respond-to(medium) {
                    border-left: unset;
                    margin-right: 2rem;
                }
                &--highlighted {
                    color: #484848;
                    border-radius: 10px;
                    background-color: #eeeeee;
                    display: flex;
                    align-items: center;
                    padding: 0.2rem 0.7rem;
                    @include respond-to(medium) {
                        margin-right: 0;
                    }
                    img {
                        margin-right: 0.5rem;
                        height: 100%;
                    }
                }
            }
        }
    }
    &__list-wrapper {
        min-height: 400px;
        // width: 100%;
        padding-bottom: 2rem;
        max-height: 220px;
        overflow-x: auto;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        margin-left: 10%;
        margin-right: 10%;
        &__title {
            font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
            color: #37454d;
            text-align: left;
            font-weight: bold;
            font-size: 1.8rem;
            margin-top: 1.5rem;
        }
    }
    &__title {
        color: $color_2;
        font-size: 2.5rem;
        font-weight: bold;
        margin: 1rem 0;
    }

    &__add-user {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        margin-left: 10%;
        margin-right: 10%;
        min-height: 45vh;

        &__title {
            font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
            color: #37454d;
            text-align: left;
            font-weight: bold;
            font-size: 1.8rem;
        }
        &__assign {
            border: 1px solid #dedcdc;
            border-radius: 6px;
            padding: 2rem;
        }
        &__description {
            display: flex;
            margin-top: 0.5rem;
            font-family: "Nunito", sans-serif;
            color: #37454d;
            text-align: left;
            line-height: normal;
            font-size: 1.8rem;
            margin-bottom: 1.1rem;
            width: 100%;
            position: relative;
            flex-direction: column;

            input {
                // margin: 1rem 0;
            }
            @include respond-to(medium) {
                max-width: unset;
            }
            &--search {
                display: flex;
                flex-direction: column;
                width: 100%;
                &--input {
                    // position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-content: center;
                    margin: 1rem 0;

                    button {
                        padding: 1rem !important;
                        height: 100%;
                        margin-left: 0.5rem;
                    }
                }
                // &-action {
                //     position: absolute;
                //     right: 4%;
                //     top: 25%;
                // }
                // &-close {
                //     position: absolute;
                //     right: 11%;
                //     top: 25%;
                // }
            }
        }
        @include respond-to(medium) {
            margin-left: 4.5rem;
            margin-right: 4.5rem;
            min-height: 500px;
        }
        textarea {
            font-weight: normal;
            min-height: 5rem;
        }
        &--actions {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 1.9rem;
            button {
                height: 4rem;
                @include respond-to(medium) {
                    height: unset;
                }
            }

            @include respond-to(medium) {
                justify-content: flex-end;
                margin-top: 3rem;
                height: unset;
            }
        }
    }
}
.landlord-info-container {
    flex: 1;
    margin-top: 1rem;
    padding-bottom: 2rem;
    border-radius: 8px;
    background-color: white;
    padding: 1.5rem;

    @include respond-to(medium) {
        width: 100%;
        margin-right: 1rem;
    }
    &-title {
        font-weight: bold;
        color: $color_2;
        font-size: 1.6rem;
        margin-bottom: 1rem;
    }
    &-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 2rem 0;
        border: 1px solid #dedcdc;
        border-radius: 6px;
        padding: 2rem;
        &-info {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 20px;
            padding-right: 5px;

            label {
                width: 25%;
                font-weight: 400;
                font-size: 16px;
            }
            p {
                width: 75%;
                word-wrap: break-word;
                font-size: 14px;
            }
            @include respond-to(medium) {
                // width: 50%;
            }
        }
        &-image {
            display: block;
            margin: auto;
        }
    }
}
