/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/raleway-v17-latin/raleway-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../../fonts/raleway-v17-latin/raleway-v17-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/raleway-v17-latin/raleway-v17-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/raleway-v17-latin/raleway-v17-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../../fonts/raleway-v17-latin/raleway-v17-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../fonts/raleway-v17-latin/raleway-v17-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/nunito-v16-latin/nunito-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../../fonts/nunito-v16-latin/nunito-v16-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/nunito-v16-latin/nunito-v16-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/nunito-v16-latin/nunito-v16-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../../fonts/nunito-v16-latin/nunito-v16-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../fonts/nunito-v16-latin/nunito-v16-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-800 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  src: url('../../fonts/nunito-v16-latin/nunito-v16-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../fonts/nunito-v16-latin/nunito-v16-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/nunito-v16-latin/nunito-v16-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/nunito-v16-latin/nunito-v16-latin-800.woff') format('woff'),
    /* Modern Browsers */ url('../../fonts/nunito-v16-latin/nunito-v16-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/nunito-v16-latin/nunito-v16-latin-800.svg#Nunito') format('svg'); /* Legacy iOS */
}
