@import "../../../../../assets/styles/abstracts/breakpoints";
@import "../../../../../assets/styles/abstracts/variables";
@import "../../../../../assets/styles/abstracts/mixins";
.split-payment-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    min-height: 45vh;
    &__title {
        font-family: "Nunito-Bold", "Nunito Bold", "Nunito", sans-serif;
        color: #37454d;
        text-align: left;
        font-weight: bold;
        font-size: 1.8rem;
        margin-bottom: 2rem;
    }
    &__description {
        margin-top: 0.5rem;
        font-family: "Nunito", sans-serif;
        color: #37454d;
        text-align: left;
        line-height: normal;
        font-size: 1.8rem;
        max-width: 23rem;
        margin: 1.1rem 0;
        flex: 1;
        @include respond-to(medium) {
            max-width: unset;
        }
    }
    &__separator {
        border: lightgrey solid 1px;
        width: 100%;
    }
    &__items-wrapper {
        display: flex;
        flex-direction: column;
        background-color: $background_color_4;
        padding: 2rem;
        border-radius: 8px;
        margin-bottom: 1.4rem;
        &__header {
            display: flex;
            flex-direction: row;
            justify-content: center;
            > * {
                margin-right: 2rem;
            }
        }
        &__item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }
    @include respond-to(medium) {
        min-height: unset;
        margin-left: 4.5rem;
        margin-right: 4.5rem;
    }
    textarea {
        font-weight: normal;
        min-height: 5rem;
    }
    &--actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 1.9rem;
        margin-left: 10%;
        margin-right: 10%;
        padding-bottom: 2.5rem;
        button {
            height: 4rem;
            @include respond-to(medium) {
                height: unset;
            }
        }
        div:first-child {
            margin-right: 0.9rem;
        }
        @include respond-to(medium) {
            justify-content: flex-end;
            margin-left: 4.5rem;
            margin-right: 3.5rem;
            margin-top: 4rem;
            height: unset;
        }
    }
}
