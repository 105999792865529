@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.edit-property-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  @include respond-to(medium) {
    min-height: calc(100vh - 45px);
    align-items: flex-start;
    background-color: #f5f7fc;
    padding: 3rem 2rem 6rem 2rem;
  }

  &__copy-container {
    background: #fff;
    padding: 1rem;
    border-radius: 5px;
    font-size: 14px;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    &-copy {
      border: none;
      background: #fff;
      position: relative;
      margin: 0 0 0 1rem;
      svg {
        font-size: 20px;
        color: $color_1;
      }
    }
    &-talkbubble {
      width: 5rem;
      height: 34px;
      background: $color_1;
      position: absolute;
      border-radius: 10px;
      left: 36px;
      top: -10px;
      color: #fff;
      justify-content: center;
      display: flex;
      align-items: center;
      &::before {
        content: "";
        position: absolute;
        right: 100%;
        top: 12px;
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-right: 15px solid $color_1;
        border-bottom: 5px solid transparent;
      }
    }
  }
  &__wrapper {
    width: 100%;
    &__row {
      display: flex;
      flex-direction: column;
      width: 100%;
      @include respond-to(medium) {
        // width: 50%;
        flex-direction: row;
      }
      &__item {
        flex: 1;
        @include respond-to(medium) {
          width: 50%;
          flex-direction: row;
        }
      }
    }
  }
  &__field {
    margin: 1rem 0;
  }
  &__sub-title {
    font-weight: bold;
    color: $color_2;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  &__general {
    flex: 1;
    width: 100%;
    margin-top: 1rem;
    padding-bottom: 2rem;

    border-bottom: 1px grey solid;
  }
  &__list-wrapper {
    height: 100%;
    min-height: 400px;
    width: 100%;
    padding-bottom: 2rem;
  }
  &__title {
    color: $color_2;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 1rem 0;
  }
  &__add-new {
    display: flex;
    align-self: flex-end;
    font-size: 3.5rem;
    color: $color_2;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  &__grid-header {
    font-size: 1.3rem;
    // font-weight: bold !important;
    color: $color_2;
  }
  &__property-image {
    height: 100%;
    /* width: 150px; */
    padding: 1rem;
    border-radius: 10px;
  }
  &__field {
    margin: 1rem 0;
  }
  &__location {
    flex: 1 1;
    border-radius: 8px;
    background-color: white;
    padding: 1.5rem;
    margin-top: 1rem;
    margin: 1rem 0;
    height: 70vh;
    width: 97%;
    padding-bottom: 17rem;
    position: relative;

    &__field {
      position: absolute;
      top: 8rem;
      z-index: 10;
      border-radius: 4px;
      border: 1px solid #dedcdc;
      background-color: #ffffff;
      text-align: left;
      height: 3.5rem;
      font-size: 1.6rem;
      font-family: "Nunito-SemiBold", "Nunito SemiBold", "Nunito", sans-serif;
      color: #000000;
      padding: 2rem 1.3rem;
      outline: none;
    }
    &__header {
      font-weight: bold;
      color: #566346;
      font-size: 1.6rem;
      margin-top: 3rem;
      margin-bottom: 1rem;
    }
  }
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.9rem;
    button {
      height: 4rem;
      @include respond-to(medium) {
        height: unset;
      }
    }
    div:first-child {
      margin-right: 0.9rem;
    }
    @include respond-to(medium) {
      justify-content: flex-end;
      margin-left: 4.5rem;
      margin-top: 4rem;
      height: unset;
    }
  }
}
